import axios from "@/plugins/http.service.js";

export default {
  gettypes() {
    return axios.post(`type/get-types`);
  },
  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("type/archive", data);
  },
};
