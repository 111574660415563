import axios from "@/plugins/http.service.js";

export default {
  getwarehouses() {
    return axios.post(`warehouse/get-warehouse`);
  },
  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("warehouse/archive", data);
  },
};
