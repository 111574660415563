import axios from "@/plugins/http.service.js";

export default {
  getdrivers() {
    return axios.get(`driver/get-drivers`);
  },


  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("driver/archive", data);
  },

};
