import axios from "@/plugins/http.service.js";

export default {
  getDrivers() {
    return axios.get(`driver/get-drivers`);
  },
  getStudents() {
    return axios.get(`v1/school-bus/students`);
  },
  addStudent(data) {
    return axios.post(`v1/school-bus/students`, data);
  },
  /*editStudent(data, student_id) {
    return axios.put(`v1/school-bus/students/${student_id}`, data);
  },*/
  getGuardians() {
    return axios.get("v1/school-bus/guardians");
  },

  archive(student_id) {
    return axios.delete(`v1/school-bus/students/${student_id}`);
  },
};
