import api from "./api.js";
import destinationApi from "../destination/api.js";
import settings from "@/plugins/settings.js";
import draggable from "vuedraggable";
import AddDriverDialog from '@/components/dialogs/add_driver_dialog.vue';
import AddWarehouseDialog from '@/components/dialogs/add_warehouse_dialog.vue';
import AddTypeDialog from '@/components/dialogs/add_type_dialog.vue';





var trip_term = localStorage.getItem("trip_term") || "Trip";
var driver_term = localStorage.getItem("driver_term") || "Driver";
var destination_term = localStorage.getItem("destination_term") || "Destination";
var currency = settings.fromNameToSymbol(localStorage.getItem("currency") || "Euro");
var warehouse_term = localStorage.getItem("warehouse_term") || "Origin"
var bus_term = localStorage.getItem("bus_term") || "Vehicle"





export default {
  components: {
    draggable,
    AddDriverDialog,
    AddWarehouseDialog,
    AddTypeDialog
  },
  data: () => ({
    tenantType: sessionStorage.getItem("tenant_type") ?? null,
    searchTermDriver: '',
    searchTermWarehouse: '',
    searchTermTripType: '',

    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    bigDialog: false,
    search: "",
    tabs: null,
    driverDisable: true,
    selectedItem: 1,
    totalItems: null,
    clicked: false,
    text: "",
    id: null,
    info: [],
    trip_term: trip_term,
    driver_term: driver_term,
    destination_term: destination_term,
    currency: currency,
    destinationExceptionDialog: false,
    originExceptionDialog: true, 
    showDrivingTimeRange: false,
    showStayingTimeRange: false,
    bus_term: bus_term,
    departure_time_range: null,
    allowedEdit: true,

    textProps: {
      "v-model": "msg",
    },
    dateProps: {
      color: "primary",
    },
    timeProps: {
      format: "24hr",
      color: "primary",

        landscape: true,
      },
      NormalRules: [(v) => !!v || "Field is required"],
      NumberRules: [
        (v) => v === null || v === undefined || v === "" || v >= 0 || "Please enter a non-negative number",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length <= 10) || "Password must be less than 10 characters",
        (v) => (v && v.length >= 4) || "Minimum 4 Characters",
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      types: [],
      selected_start_date: null,
      clear: false,
      destinationDialog: false,
      currentDestination: null,
      currentDestinationIndex: null,
      selected_driver: null,
      selected_warehouse: null,
      selected_type: null,
      start_date: null,
      end_date: null,
      repeat_type: null,
      repeat_interval: 1,
      days_of_week: null,
      selectedDays: [],
      data: [],
      pictures: [],
      type: null,
      myCurrentData: [],
      warehouses: [],
      drivers: [],
      options: {page: 1, itemsPerPage: 10},
      repeat_enum: ["daily", "weekly", "monthly"],
      days_enum: [
        { name: "Monday", value: 1 },
        { name: "Tuesday", value: 2 },
        { name: "Wednesday", value: 3 },
        { name: "Thursday", value: 4 },
        { name: "Friday", value: 5 },
        { name: "Saturday", value: 6 },
        { name: "Sunday", value: 7 },
      ],
      infoDialog: false,
      warehouse_term: warehouse_term,
      fdate: null,
      tdate: null,
      note: "",
      dialogTitle: '',
      destinations: [],
      destination_id: null,
      trip_destinations: [],
      intervalId: null,
      languages: [],
      place: { name: "", lat: null, lon: null },
      newDriver: {
        fname: '',
        lname: '',
        email: '',
        password: '',
        bus: null,
        hourly_rate: 0,
        language: null,
      },
      newWarehouse: {
        title: null,  
        lat: null,
        lon: null,   
      },
      newTripType: {
        title: null,
        time_stop: null,
      },
      showDriverCombobox: true,
      showWarehouseCombobox: true,
      showTripTypeCombobox: true,
      fmenu: false,
      tmenu: false,
      show1: false,
      showPassword: false,
      json_fields: {
        Id: "id",
        [`${driver_term} Name`]: "driver.fullname",
        Warehouse: "warehouse.title",
        Type: "type.title",
        Status: "flag",
        "Start At": "start_date",
        "Picked On": "started_date",
        "Delivered On": "ended_date",
        "Nb of Accidents": "accidents.length",
        "Nb of Fines": "fines.length",
      },
      headers: [
        { text: `${driver_term} Name`, value: "driver.fullname", align: "center" },
        { text: warehouse_term, value: "warehouse.title", align: "center" },
        { text: "Type", value: "type0.title", align: "center" },
        { text: "Start Date", value: "start_date", align: "center" },
        { text: "End Date", value: "end_date", align: "center" },
        { text: "Repeat Type", value: "repeat_type", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],      
      title: "",
      addDriverDialog: false,
      addWarehouseDialog: false,
      addTripTypeDialog: false,
    }),
    mounted() {
      window.addEventListener("keyup", this.handleKeyPress);
      api
        .gettypes()
        .then((res) => {
          // this.loading = false;
          if (res.data.responseCode === 1) {
            this.types = res.data.data;
          }
        })
        .catch((error) => {
          this.error = error;
          // this.loading = false;
          this.errorDialog = true;
        });
      var myCurrentDate = new Date();
      var myFutureDate = new Date();

    this.fdate = myCurrentDate.toISOString().substr(0, 10);
    this.tdate = myFutureDate.toISOString().substr(0, 10);

    api
      .getwarehouses()
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            let w = { id: element.id, title: element.title };
            this.warehouses.push(w);
          });
        }
      })
      .catch((error) => {
        this.$snackbar.show(error, "error");

        // this.loading = false;
        this.errorDialog = true;
      });
  },
  beforeDestroy(){
    window.removeEventListener("keyup", this.handleKeyPress);
  },
  methods: {
    onTypeAdded(newlyAddedTripType){
      this.types.push(newlyAddedTripType)
      this.selected_type = newlyAddedTripType;
    },
    fetchStudentDestinations(driver_id) {
      api.getDriverStudents(driver_id).then((res) => {
        if (res.data.responseCode === 1) {
          this.students_destination = res.data.data.map((student) => ({
            student_fname: student.fname,
            student_lname: student.lname,
            destination_name: student.stop_name,
            destination_lat: student.stop_lat,
            destination_lon: student.stop_lon,
            destination_text: student.fname + " " + student.lname + ": " + student.stop_name,
          }));
        }
      });
    },
    
    selectDestinationSchoolBus(selectedDestination) {  
      if (selectedDestination && selectedDestination.destination_name) {
        this.currentDestination.destination_name = selectedDestination.destination_name;
        this.currentDestination.destination_lat = selectedDestination.destination_lat;
        this.currentDestination.destination_lon = selectedDestination.destination_lon;
        this.currentDestination.student_fname = selectedDestination.student_fname;
        this.currentDestination.student_lname = selectedDestination.student_lname;
      } else {
        this.currentDestination.destination_name = null;
        this.currentDestination.destination_lat = null;
        this.currentDestination.destination_lon = null;
        this.currentDestination.student_fname = null;
        this.currentDestination.student_lname = null;
      }
      this.trip_destinations[this.currentDestinationIndex] = this.currentDestination;
    },
    
    onInfo(item){
      this.showDialog(item);
        this.dialogTitle = "Show";
        this.allowedEdit = false;
        this.showDrivingTimeRange = true;
        this.showStayingTimeRange = true;
    },
    toggleStayingTimeRange() {
      if (this.currentDestination)
        this.showStayingTimeRange = !this.showStayingTimeRange;
    },
    toggleDrivingTimeRange() {
      if (this.currentDestination) {
        this.showDrivingTimeRange = !this.showDrivingTimeRange;
      }
    },
    handleIconClick(from, index = null) {
      if (from === "origin") {
        this.destinationDialog = false;
        this.originExceptionDialog = true;
        this.currentDestination = null;
        this.currentDestinationIndex = null;
      } else if (index !== null && this.trip_destinations[index]) {
        this.currentDestinationIndex = index;
        this.currentDestination = this.trip_destinations[index];
        this.destinationExceptionDialog = true;
        this.destinationDialog = false;
        this.originExceptionDialog = false;
      } else {
        console.error("Destination not found or invalid index provided.");
      }
    },
    saveDestination(index) {
      if (index === -1) {
        this.destinationDialog = false;
        this.originExceptionDialog = false;
        this.destinationExceptionDialog = false;
        return;
      }
    
      const destination = this.trip_destinations[index];
    
      if (destination.tasks && destination.tasks.length > 0) {
        if (destination.tasks.some((task) => {
          return task.description == "";
        }) ){
          this.$snackbar.show("Cannot save destination with pending tasks. Please review the tasks.", "error");
          return;
        }
        
      }
    
      if (!destination.destination_name) {
        this.$snackbar.show("Can't save destination (missing name)", "success");
        return;
      }
    
      this.destinationDialog = false;
      this.originExceptionDialog = false;
      this.destinationExceptionDialog = false;
    },

    onPagination(newOptions) {
      this.options = { ...newOptions }; // Spread to ensure reactivity
      this.fetch();
    },
  
    addTask(destination) {
      if (!destination.tasks) {
        destination.tasks = [];
      }
      destination.tasks.push({ description: '', manager_approval: false, client_approval: false, });
    },
    removeTask(destination, taskIndex) {
      if (destination.tasks && destination.tasks.length > taskIndex) {
        destination.tasks.splice(taskIndex, 1);
      }
    },
    closeAddDriverDialog() {
      this.showDriverCombobox = false;
      this.selected_driver = null;
      this.$nextTick(() => {
        this.showDriverCombobox = true;
      });
      this.addDriverDialog = false;
    },
  
    closeAddWarehouseDialog() {
      this.showWarehouseCombobox = false;
      this.newWarehouse.title = null;
      this.$nextTick(() => {
        this.showWarehouseCombobox = true;
      });
      this.addWarehouseDialog = false;
    },
  
    closeAddTripTypeDialog() {
      this.showTripTypeCombobox = false;
      this.newTripType.title = null;
      this.$nextTick(() => {
        this.showTripTypeCombobox = true;
      });
      this.addTripTypeDialog = false;
    },
  
    customDriverFilter(item, queryText) {
      if (item.id === -1) return true;
      return item.fullname.toLowerCase().includes(queryText.toLowerCase());
    },
    customWarehouseFilter(item, queryText) {
      if (item.id === -1) return true;
      return item.title.toLowerCase().includes(queryText.toLowerCase());
    },

    handleTripTypeChange(selected) {
      if (selected && selected.id === -1) {
        
        this.selected_type = null;
        this.showAddTripTypeDialog();
      }
    },

    showAddTripTypeDialog(){
      this.addTripTypeDialog = true;
      this.newTripType = {
        title: null,
        time_stop: null,
      };
    },

    handleDriverChange(selected) {
      if (selected && selected.id === -1) {
        this.selected_driver = null;
        this.showAddDriverDialog();
        return;
      }

      if (this.tenantType == "school_bus"){
        this.fetchStudentDestinations(selected.id)
      }
    },
    handleWarehouseChange(selected) {
      if (selected && selected.id === -1) {
        this.selected_warehouse = null;
        this.showAddWarehouseDialog();
      }
    },


    showAddWarehouseDialog(){
      this.addWarehouseDialog = true;
      this.newWarehouse = {
        title: null,
        lat: null,
        lon: null,
      }
    },
  
    showAddDriverDialog() {
      this.addDriverDialog = true;
    },

    async onDriverAdded(newlyAddedDriver){
      await this.updateDrivers();
      const newDriver = {
        id: newlyAddedDriver.id,
        fullname: `${newlyAddedDriver.fname} ${newlyAddedDriver.lname}`,
        email: newlyAddedDriver.email,
        bus : newlyAddedDriver.bus,
        hourly_rate: newlyAddedDriver.hourly_rate,
        language: newlyAddedDriver. language
      };
      this.drivers.push(newDriver);
      this.selected_driver = newDriver;
    },

    async onWarehouseAdded(newlyAddedWarehouse){
      this.warehouses.push(newlyAddedWarehouse);
      this.selected_warehouse = newlyAddedWarehouse;
    },

    handleKeyPress(event) {
      if (event.key === "Enter") {
        switch (true) {
          case this.dialog:
            this.save();
            return;
          case this.deleteDialog:
            this.agreeOnDelete();
            return;
          default:
            break;
        }
      }
    },

    parseDate(dateString) {

      if (!dateString) return null;
    

      const [datePart, timePartWithAmPm] = dateString.split(", ");
      if (!datePart || !timePartWithAmPm) return null;
    
      const [monthStr, dayStr, yearStr] = datePart.split("/"); 
      const month = parseInt(monthStr, 10);
      const day   = parseInt(dayStr, 10);
      const year  = parseInt(yearStr, 10);
    

      const [timePart, ampm] = timePartWithAmPm.split(" ");
      if (!timePart || !ampm) return null;
    
      const [hoursStr, minutesStr, secondsStr] = timePart.split(":"); 
      let hours   = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);
      const seconds = parseInt(secondsStr, 10);

      if (ampm === "PM" && hours < 12) {
        hours += 12;
      } else if (ampm === "AM" && hours === 12) {
        hours = 0;
      }
    
      const parsedDate = new Date(year, month - 1, day, hours, minutes, seconds);
      return parsedDate;
    }, 
    
    

    async fetch() {
      this.data = [];
      const {page, itemsPerPage} = this.options;
      await api
        .gettrips(page, itemsPerPage)
        .then((res) => {
          if (res.data.responseCode === 1) {
            this.totalItems = res.data.data.pagination.total_items;
            res.data.data.trips.forEach((element) => {
              element.driver =
                element.driver != null
                  ? {
                      fullname:
                        element.driver.fname + " " + element.driver.lname,
                      id: element.driver.id,
                    }
                  : { fullname: "N" + "/" + "A", id: -1 };
              // element.warehouse = {
              //   title: element.warehouse.title,
              //   id: element.warehouse.id,
              // };

              element.started_date = this.convertUTCDateToLocalDate(
                element.started_date
              );
              element.created_date = this.convertUTCDateToLocalDate(
                element.created_date
              );
               element.start_date = this.convertUTCDateToLocalDate(
                 element.start_date
               );
               element.end_date = this.convertUTCDateToLocalDate(
                 element.end_date
               );

              this.data.push(element);
            });
          }
        })
        .catch((error) => {
          this.$snackbar.show()

          this.snackbarMessage = error;
          this.snackbar = true;
          this.loading = false;
          this.errorDialog = true;
        });

      await destinationApi.getdestinations().then((res) => {
        if (res.data.responseCode === 1) {
          this.destinations = res.data.data;
        }
      });

      this.loading = false;
    },

    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },

    back() {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
    },

    focus(type, info) {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
      this.info = [];
      switch (type) {
        case 0: //Fines
          this.type = type;
          this.info = info;
          break;
        case 1: // Accidents
          this.type = type;
          this.info = info;
          break;
        default:
          break;
      }
      this.infoDialog = true;
    },

    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }
    },

    convertToUTCDate(date) {
      const localDate = new Date();
      const utcDate = new Date(date ? date : localDate);
      const utcDateTime = new Date(utcDate.toISOString());

      // Get date components
      const year = utcDateTime.getUTCFullYear();
      const month = utcDateTime.getUTCMonth() + 1; // Months are zero-indexed
      const day = utcDateTime.getUTCDate();
      const hours = utcDateTime.getUTCHours();
      const minutes = utcDateTime.getUTCMinutes();
      const seconds = utcDateTime.getUTCSeconds();

      // Format the date
      const formattedDate = `${year}-${this.pad(month)}-${this.pad(
        day
      )} ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;

      return formattedDate;
    },

    pad(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    },

    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },

    async agreeOnDelete() {
      this.clicked = true;

      this.loading = true;
      await api
        .delete(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.data.indexOf(this.selectedObject);
            this.$delete(this.data, index);
            this.$snackbar.show(trip_term +" Deleted Succesfully", "success")

          } else {
            this.$snackbar.show(res.data.responseMessage, "error");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.errorDialog = true;
          this.deleteDialog = false;
          this.clicked = false;
        });

      await this.fetch();
    },

    async showDialog(item) {
      this.allowedEdit = true;
      this.valid = true;
      if (item == null) {
        this.selectedObject = { id: -1 };
        this.selected_driver = null;
        this.selected_warehouse = null;
        this.type = null;
        this.id = null;
        this.selected_start_date = new Date(Date.now());
        this.end_date = new Date(Date.now());

        this.place = { name: null, lat: null, lon: null };
        this.trip_destinations = [];
        this.note = null;
      } else {
        console.log(item);

        this.selectedObject = item;
        this.selected_warehouse = item.warehouse;
        this.selected_driver = item.driver;
        this.selected_type = item.type0;
        this.id = item.id;
        this.selected_start_date = this.parseDate(item.start_date);
        this.start_date = this.parseDate(item.start_date);
        this.end_date = this.parseDate(item.end_date);

        this.note = item.note;
        this.destination_id = item.destination_id;
        this.place = {
          name: item.destination_name,
          lat: item.destination_lat,
          lon: item.destination_lon,
        };

        this.repeat_type = item.repeat_type;
        this.selectedDays = item.days_of_week.split(",");
        this.selectedDays = this.selectedDays.map((val) => Number(val));
        this.days_of_week = item.days_of_week;
        this.departure_time_range = item.departure_time_range;
  
        this.trip_destinations = item.tripDestinations
          ? item.tripDestinations.map((des) => ({
              destination_id: des.destination_id,
              destination_lat: des.destination?.lat || des.destination_lat,
              destination_lon: des.destination?.lon || des.destination_lon,
              destination_name: des.destination_name,
              tasks: des.tasks.map((task) =>{
                return {
                  description: task.description,
                  client_approval: task.client_approval,
                  manager_approval: task.manager_approval,
                  ...task
                  
                }}).sort((task_a, task_b) => task_a.priority - task_b.priority),
              staying_time: des.staying_time,
              driving_time: des.driving_time,
              staying_time_range: des.staying_time_range,
              driving_time_range: des.driving_time_range,
            }))
          : [];
          this.fetchStudentDestinations(this.selected_driver.id);
      }

      this.dialog = true;
    },

    async save() {
      let ok = true;
      this.trip_destinations.forEach(destination => {
        if (!destination.destination_name) {
          ok = false;
        }
        
        destination.staying_time = destination.staying_time != null ? parseInt(destination.staying_time, 10) : null;
        destination.driving_time = destination.driving_time != null ? parseInt(destination.driving_time, 10) : null;
        destination.staying_time_range = destination.staying_time_range != null ? parseInt(destination.staying_time_range, 10) : null;
        destination.driving_time_range = destination.driving_time_range != null ? parseInt(destination.driving_time_range, 10) : null;
      });
      this.clicked = true;

      if (!this.$refs.form.validate() || ok == false) {
        let snackbarMessage = ok ? "Please fill the form correctly" : ("Fill the " + destination_term + " correctly");
        this.clicked = false;
        this.$snackbar.show(snackbarMessage);

        return false;
      }
      this.loading = true;
      this.departure_time_range = this.departure_time_range ? parseInt(this.departure_time_range) : null;
      this.days_of_week.split(",").sort().reduce((prev, x) => prev + ','+ x)
      if (this.selectedObject.id != -1) {
        await this.editScheduledTrip();
      } else {
        await this.addScheduledTrip();
      }

      this.cleanForm()
      this.fetch();
    },

    async editScheduledTrip(){
      await api
      .save(
        this.id,
        this.selected_driver.id,
        this.selected_warehouse.id,
        this.selected_type.id,
        this.convertToUTCDate(this.selected_start_date),
        this.note,
        this.trip_destinations,
        this.convertToUTCDate(this.end_date),
        this.repeat_type,
        this.repeat_interval,
        this.days_of_week,
        this.departure_time_range
      )
      .then((res) => {
        this.resetData();
        this.loading = false;
        this.dialog = false;
        this.clicked = false;
        if (res.data.responseCode === 1) {
          var index = this.data.indexOf(this.selectedObject);
          this.data[index].driver = {
            id: this.selected_driver.id,
            fullname: this.selected_driver.fullname,
          };
          this.data[index].warehouse = {
            id: this.selected_warehouse.id,
            title: this.selected_warehouse.title,
          };
          this.data[index].type0 = this.selected_type;
          this.data[index].start_date = this.convertUTCDateToLocalDate(
            this.selected_start_date
          );

          this.data[index].started_date = this.convertUTCDateToLocalDate(
            this.data[index].started_date
          );
          this.data[index].created_date = this.convertUTCDateToLocalDate(
            this.data[index].created_date
          );
          this.data[index].start_date = this.convertUTCDateToLocalDate(
            this.data[index].start_date
          );
          this.data[index].ended_date = this.convertUTCDateToLocalDate(
            this.data[index].ended_date
          );
          this.$snackbar.show(this.trip_term + " updated successfully.", "success");
        } else {
          this.$snackbar.show(res.data.responseMessage, "success");
        }
      })
      .catch((error) => {
        this.$snackbar.show(error, "error");
        this.loading = false;
        this.dialog = false;
      });
    },

    async addScheduledTrip(){
      await api
      .add(
        this.selected_driver.id,
        this.selected_warehouse.id,
        this.selected_type.id,
        this.convertToUTCDate(this.selected_start_date),
        this.note,
        this.trip_destinations,
        this.convertToUTCDate(this.end_date),
        this.repeat_type,
        this.repeat_interval,
        this.days_of_week,
        this.departure_time_range
      )
      .then((res) => {
        this.loading = false;

        this.dialog = false;
        this.clicked = false;
        if (res.data.responseCode === 1) {
          this.data.push({
            id: res.data.data.id,
            driver: {
              id: res.data.data.driver_id,
              fullname: this.selected_driver.fullname,
            },
            warehouse: {
              id: res.data.data.warehouse_id,
              title: this.selected_warehouse.title,
            },
            type0: this.selected_type,
            start_date: this.convertUTCDateToLocalDate(
              this.selected_start_date
            ),
            flag: "Not Picked",
            created_date: this.created_date,
            ended_date: null,
            started_date: null,
            accidents: [],
            fines: [],
          });
          this.$snackbar.show(this.trip_term + " added successfully.", "success");
        } else {
          this.$snackbar.show(res.data.responseMessage, "success");
        }
      })
      .catch((error) => {
        this.$snackbar.show(error, "error");
        this.loading = false;
        this.dialog = false;
        this.clicked = false;
      });
    },

    cleanForm(){
      this.selectedObject = { id: -1 };
      this.selected_driver = null;
      this.selected_warehouse = null;
      this.type = null;
      this.id = null;
      this.selected_start_date = new Date(Date.now());
      this.end_date = new Date(Date.now());

      this.place = { name: null, lat: null, lon: null };
      this.trip_destinations = [];
      this.note = null;
      this.selectedDays = [];
      this.days_of_week = null;
    },

    onPlaceSelected(place, index) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      // this.place = { name: place.name, lat: latitude, lon: longitude };

      this.trip_destinations[index].destination_id = null;
      this.trip_destinations[index].destination_name = place.formatted_address;
      this.trip_destinations[index].destination_lat = latitude;
      this.trip_destinations[index].destination_lon = longitude;
    },

    onPlaceInputChange(place, index) {
      this.trip_destinations[index].destination_name =
        place === "" ? null : place;
      this.trip_destinations[index].destination_lat = null;
      this.trip_destinations[index].destination_lon = null;
    },

    closeDialog() {
      this.dialog = false;
      this.selectedObject = { id: -1 };
      this.selected_driver = null;
      this.selected_warehouse = null;
      this.type = null;
      this.id = null;
      this.selected_start_date = new Date(Date.now());
      this.end_date = new Date(Date.now());

      this.place = { name: null, lat: null, lon: null };
      this.trip_destinations = [];
      this.selectedDays = [];
      this.note = null;
      this.days_of_week = null;
      this.bigDialog = false;
      this.resetData();
    },

    addDestination() {
      this.currentDestination = {
        destination_id: null,
        destination_name: null,
        destination_lat: null,
        destination_lon: null,
        tasks: [],
        staying_time: null,
        driving_time: null,
        staying_time_range: null,
        driving_time_range: null,
      };

      this.destinationDialog = true;
      this.trip_destinations.push(this.currentDestination);
      this.currentDestinationIndex = this.trip_destinations.length - 1;
    },

    removeDestination(index) {
      this.trip_destinations.splice(index, 1);
      let len = this.trip_destinations.length;

      if (len === 0) {
        this.destinationDialog = false;
        this.currentDestination = null;
        this.currentDestinationIndex = null;
        return;
      } else {
        if (index > 0) {
          this.currentDestinationIndex = index - 1;
        } else {
          this.currentDestinationIndex = 0;
        }
        this.currentDestination =
          this.trip_destinations[this.currentDestinationIndex];
      }
    },

    updateDestinationName(destination) {
      if (!destination.destination_id) {
        destination.destination_name = "";
        this.clear = true;
        return;
      }
      this.clear = false;

      const selectedDestination = this.destinations.find(
        (dest) => dest.id === destination.destination_id
      );

      if (selectedDestination) {
        destination.destination_name = selectedDestination.name;
      }
    },

    editDestination(destination, index) {
      this.destinationDialog = true;
      this.currentDestination = destination;
      this.currentDestinationIndex = index;
    },

    formatDate(date) {
      if (!date) return "";
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const dayName = days[date.getDay()];
      const monthName = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      const hours = this.pad(date.getHours());
      const minutes = this.pad(date.getMinutes());
      const seconds = this.pad(date.getSeconds());

      return `${dayName} ${monthName} ${day} ${year} ${hours}:${minutes}:${seconds}`;
    },

    resetData() {
      this.destinationDialog = false;
      this.currentDestination = null;
      this.currentDestinationIndex = null;
      this.showDrivingTimeRange = false;
      this.showStayingTimeRange = false;
      this.destinationExceptionDialog = false;
      this.originExceptionDialog = true;
    },

    updateDrivers(){
      api
        .getdrivers()
        .then((res) => {
          this.drivers = [];
          this.driverDisable = true;

          if (res.data.responseCode === 1) {
            this.driverDisable = false;

            res.data.data.forEach((element) => {
              if (element.archived === 1) return;
              let d = {
                id: parseInt(element.id),
                fullname: element.fname + " " + element.lname,
              };
              this.drivers.push(d);
            });
          }
        })
        .catch((error) => {
          this.$snackbar.show(error, "error");
          this.loading = false;
          this.errorDialog = true;
        });
    }
  },

  watch: {
    selected_start_date: function () {
      this.updateDrivers();
    },
    selectedDays(newVal) {
      // Update the days_of_week string whenever selectedDays changes
      this.days_of_week = newVal.join(",");
    },
  },

  computed: {
    filteredDrivers() {
      const searchTerm = this.searchTerm ? this.searchTerm.toLowerCase() : '';  // Ensure searchTerm is a string
      const drivers = this.drivers.filter(driver => driver?.fullname?.toLowerCase().includes(searchTerm)); // Add null checks
  
      if (searchTerm && !drivers.length) {
        return [...drivers, { id: -1, fullname: `Create a new ${this.driver_term}` }];
      }
      return drivers;
    },
    filteredWarehouses() {
      const searchTermWarehouse = this.searchTermWarehouse ? this.searchTermWarehouse.toLowerCase() : '';  // Ensure searchTermWarehouse is a string
      const warehouses = this.warehouses.filter(warehouse => warehouse?.title?.toLowerCase().includes(searchTermWarehouse)); // Add null checks
  
      if (searchTermWarehouse && !warehouses.length) {
        return [...warehouses, { id: -1, title: `Create a new ${warehouse_term}` }];
      }
      return warehouses;
    },
    filteredTripTypes() {
      const searchTermTripType = this.searchTermTripType ? this.searchTermTripType.toLowerCase() : '';  // Ensure searchTermTripType is a string
      const trip_types = this.types.filter(type => type?.title?.toLowerCase().includes(searchTermTripType)); // Add null checks
  
      if (searchTermTripType && !trip_types.length) {
        return [...trip_types, { id: -1, title: `Create a new ${trip_term} type` }];
      }
      return trip_types;
    },
  
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
  },
};
