import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrappperheader"},[_c('div',{staticClass:"headertitle"},[_c('p',{staticClass:"pagetitle"},[_c(VIcon,{staticClass:"mainpageicon",attrs:{"fab":"","large":""}},[_vm._v(_vm._s(this.$route.meta.mainicon))]),_vm._v(" "+_vm._s(this.tenantType === "namat" ? "Staff" : _vm.driver_term + "s")+" ")],1)]),_c('div',{staticClass:"headerbuttons"},[_c('div',{staticClass:"btn"},[_c(VBtn,{on:{"click":function($event){return _vm.showDialog(null)}}},[_vm._v(" Add ")])],1),_c('div',{staticClass:"btn1"},[_c('export-excel',{attrs:{"data":_vm.myCurrentData,"fields":_vm.json_fields,"worksheet":_vm.driver_term,"name":_vm.driver_term + 's.xls'}},[_c(VBtn,[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-file-excel")]),_vm._v("Export ")],1)],1)],1)])]),_c(VCard,[_c(VCardTitle,[_c(VSpacer),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.ddata,"search":_vm.search,"sort-by":"id","sort-desc":true},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'DriverFile',
            params: { id: item.id, name: item.fname + ' ' + item.lname },
          },"custom":""}},[_c(VIcon,{staticStyle:{"color":"#333333"}},[_vm._v("description")])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'OneDriverFinance',
            params: { id: item.id, name: item.fname + ' ' + item.lname },
          },"custom":""}},[_c(VIcon,{staticStyle:{"color":"#333333"}},[_vm._v(_vm._s(_vm.currency))])],1),_c(VIcon,{on:{"click":function($event){return _vm.showDialog(item)}}},[_vm._v("edit")]),_c(VIcon,{on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("delete")])]}},{key:"item.bus",fn:function(ref){
          var item = ref.item;
return [(item.bus === null)?_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v(" Not Assigned")]):_c('span',[_vm._v(_vm._s(item.bus.reference))])]}},{key:"item.hourly_rate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.hourly_rate).toFixed(2))+" "+_vm._s(_vm.currency)+" ")]}},{key:"item.buses[0].reference",fn:function(ref){
          var item = ref.item;
return [(item.buses.length == 0)?_c('span',{staticClass:"font-weight-bold red--text"},[_vm._v(" "+_vm._s("Not Assigned yet")+" ")]):_c('span',[_vm._v(" "+_vm._s(item.buses[0].reference)+" ")])]}}],null,true)},[_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"slot":"progress","color":"red","indeterminate":""},slot:"progress"})],1)],1),_c('add-driver-dialog',{attrs:{"dialog":_vm.dialog,"method":_vm.selectedObject.id === -1 ? 'ADD' : 'UPDATE',"title":_vm.selectedObject.id === -1 ? ("Add a new " + _vm.driver_term) : ("Update " + _vm.driver_term),"driver":_vm.selectedObject.id === -1 ? {} : _vm.selectedObject,"driver-term":_vm.driver_term,"bus-term":_vm.bus_term,"currency":_vm.currency,"index":_vm.indexDriver},on:{"close-driver-dialog":function($event){_vm.dialog = false},"driver-updated":_vm.onDriverUpdated,"driver-added":_vm.onDriverAdded}}),_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Confirmation Required ")]),_c(VCardText,[_vm._v("Are you sure you want to delete this User, This action cannot be undone!")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"btn1",attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"btn",attrs:{"disabled":_vm.clicked,"text":""},on:{"click":_vm.agreeOnDelete}},[_vm._v(" Confirm ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }