import api from './api.js';
import AddTypeDialog from '@/components/dialogs/add_type_dialog.vue';

var trip_term = localStorage.getItem("trip_term") || "Trip";
export default {
    components:{
        AddTypeDialog
    },
    data: () => ({
        tenantType: (sessionStorage.getItem("tenant_type") ?? null),
        valid: false,
        dialog: false,
        selectedObject: { id: -1 },
        loading: true,
        deleteDialog: false,
        title: null,
        time_stop: null,
        clicked: false,
        search: '',
        id: null,
        index: -1,
        trip_term: trip_term,
        myCurrentData: [],
        json_fields: {
            'Id': 'id',
            'Name': 'title',
        },
        data: [],
        headers: [
            { text: 'Name', align: 'center', value: 'title'},
            { text: 'Actions', value: 'actions', align: 'center'},
        ],
    }),
    mounted() {
        window.addEventListener("keyup", this.handleKeyPress);
        api.gettypes().then((res) => {
            this.loading = false;
            if (res.data.responseCode === 1) {
                this.data = res.data.data;
            }
        }).catch((error) => {
            this.error = error;
            this.loading = false;
            this.errorDialog = true;
        });
    },
    beforeDestroy(){
        window.removeEventListener("keyup", this.handleKeyPress);
    },
    methods: {
        handleKeyPress(event) {
            if (event.key === "Enter") {
              if(this.dialog) this.save();
              if(this.deleteDialog) this.agreeOnDelete();
            }
            
          },
        getFiltered(e) {
            this.myCurrentData = e;
            //output the filtered items
        },
        onDelete(item) {
            this.selectedObject = item;
            this.deleteDialog = true;
        },
        showDialog(item) {
            this.selectedObject = item;
            this.index = item ? this.data.findIndex((obj) => obj.id == item.id) : -1;
            this.dialog = true;
        },
        onTypeAdded(newlyAddedTripType){
            this.data.push(newlyAddedTripType);
        },
        onTypeUpdated(updatedType, index) {
            if (index !== -1) {
                this.$set(this.data, index, { ...updatedType });
            }
        },
        agreeOnDelete() {
            this.clicked = true;

            this.loading = true;
            api.archive(this.selectedObject.id).then((res) => {
                this.loading = false;
                if (res.data.responseCode === 1) {
                    this.deleteDialog = false;
                    this.clicked = false;
                    var index = this.data.indexOf(this.selectedObject);
                    this.$delete(this.data, index);
                    this.$snackbar.show(`${trip_term} type deleted successfully.`, "success");
                } else {
                    this.$snackbar.show(res.data.responseMessage, "error");
                }
            }).catch((error) => {
                this.loading = false;
                console.log(error);
                this.errorDialog = true;
            });
        },

    },
}
