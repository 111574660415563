import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrappperheader"},[_c('div',{staticClass:"headertitle"},[_c('p',{staticClass:"pagetitle"},[_c(VIcon,{staticClass:"mainpageicon",attrs:{"fab":"","large":""}},[_vm._v(_vm._s(this.$route.meta.mainicon))]),_vm._v(" "+_vm._s(this.tenantType === "namat" ? "Performance summary" : this.$route.meta.title.replace("Driver", _vm.driver_term))+" ")],1)]),_c('div',{staticClass:"headerbuttons"},[_c('div',{staticClass:"btn1"},[_c('export-excel',{attrs:{"data":_vm.myCurrentData,"fields":_vm.json_fields,"worksheet":_vm.driver_term + 'Summary',"name":_vm.driver_term + 'Summary.xls'}},[_c(VBtn,[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-file-excel")]),_vm._v("Export ")],1)],1)],1)])]),_c(VCard,[_c(VCardTitle,[_c(VSpacer),_c(VRow,{staticClass:"pa-0 ma-0"},[_c(VCol,{attrs:{"cols":_vm.Column}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{attrs:{"cols":_vm.Column}},[_c('div',[_c(VMenu,{ref:"fmenu",attrs:{"close-on-content-click":false,"return-value":_vm.fdate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.fdate=$event},"update:return-value":function($event){_vm.fdate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"From","hide-details":"","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fdate),callback:function ($$v) {_vm.fdate=$$v},expression:"fdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fmenu),callback:function ($$v) {_vm.fmenu=$$v},expression:"fmenu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","color":"red"},model:{value:(_vm.fdate),callback:function ($$v) {_vm.fdate=$$v},expression:"fdate"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.fmenu = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.fmenu.save(_vm.fdate), _vm.fetch()}}},[_vm._v(" OK ")])],1)],1)],1)]),_c(VCol,{attrs:{"cols":_vm.Column}},[_c('div',[_c(VMenu,{ref:"tmenu",attrs:{"close-on-content-click":false,"return-value":_vm.tdate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.tdate=$event},"update:return-value":function($event){_vm.tdate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"hide-details":"","label":"To","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.tdate),callback:function ($$v) {_vm.tdate=$$v},expression:"tdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.tmenu),callback:function ($$v) {_vm.tmenu=$$v},expression:"tmenu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","color":"red"},model:{value:(_vm.tdate),callback:function ($$v) {_vm.tdate=$$v},expression:"tdate"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.tmenu = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.tmenu.save(_vm.tdate), _vm.fetch()}}},[_vm._v(" OK ")])],1)],1)],1)])],1)],1),_c(VDataTable,{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.data,"search":_vm.search,"sort-by":"driver.id","sort-desc":true},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.TotalWorkingHours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.TotalWorkingHours)+" "),_c('strong',[_vm._v("Hours ")]),_vm._v(" "+_vm._s(item.TotalWorkingMinutes)+" "),_c('strong',[_vm._v("Minutes ")])]}},{key:"item.totalAmmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalAmmount)+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.currency)+" ")])]}},{key:"item.driver.hourly_rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.driver.hourly_rate)+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.currency)+" ")])]}},{key:"item.totalAccidents",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalAccidents)+" "),_c('strong',[_vm._v(" A ")])]}},{key:"item.totalTrips",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"color":"red","plain":"","text":"","ripple":false},on:{"click":function($event){return _vm.$router.push({
              name: 'driver-summary-trips',
              params: {
                fdate: _vm.fdate,
                tdate: _vm.tdate,
                driver_id: item.driver_id,
              },
            })}}},[_vm._v(" "+_vm._s(item.totalTrips)+" "),_c('strong',[_vm._v(" T ")])])]}},{key:"item.totalFuel",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalFuel)+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.volume_unit)+" ")])]}},{key:"item.TotalDieselAmount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.TotalDieselAmount)+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.currency)+" ")])]}}],null,true)},[_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"slot":"progress","color":"red","indeterminate":""},slot:"progress"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }