import api from "./api.js";
import settings from "../../plugins/settings.js";

export default {
  data: () => ({
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    tenant_type: sessionStorage.getItem("tenant_type"),
    loading: true,
    deleteDialog: false,
    search: "",
    tabs: null,
    selectedItem: 1,
    snackbar: false,
    clicked: false,
    text: "",
    id: null,
    name: "",
    settings: settings.settings,
    info: [],
    textProps: {
      "v-model": "msg",
    },
    NormalRules: [(v) => !!v || "Field is required"],
    currencies: settings.getCurrencyNames(),
    spaceUnits: settings.getSpaceUnits(),
    liquidUnits: settings.getLiquidUnits(),
    fields:[],
    types: [],
    selected_start_date: null,
    data: [],
    type: null,
    myCurrentData: [],
    infoDialog: false,
    fmenu: false,
    tmenu: false,
    show1: false,
    json_fields: {
      Id: "id",
      Name: "name",
    },
    headers: [
      {
        text: "Tenant Name",
        value: "name",
        align: "center",
      },
      { text: "Created Date", value: "created_date", align: "center" },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
    newSetting: {
      key: "",
      value: "",
    },
  }),

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      this.data = [];
      this.loading = true;

      try {
        const res = await api.gettenants();
        this.loading = false;

        if (res.data.responseCode === 1) {
          const tenants = res.data.data.map((item) => {
            item.created_date = this.convertUTCDateToLocalDate(item.created_date);
            if(item.settings){
              item.settings = Object.values(item.settings).reduce((acc, item) => {
                acc[item.key] = item.value;
                return acc;
              }, {});
            }
            return item;
          });
          this.data = tenants;
        } else {
          this.text = res.data.responseMessage;
          this.snackbar = true;
        }

        const res_tenant_types = await api.get_tenant_types();
        if (res_tenant_types.data.responseCode === 1) {
          this.tenant_types = res_tenant_types.data.data;
        } else {
          this.text = res_tenant_types.data.responseMessage;
          this.snackbar = true;
        }

      } catch (error) {
        this.text = error.message || error;
        this.snackbar = true;
        this.loading = false;
        this.errorDialog = true;
      }

    },
    
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },

    back() {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
    },

    focus(type, info) {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
      this.info = [];
      switch (type) {
        case 0: //Fines
          this.type = type;
          this.info = info;
          break;
        case 1: // Accidents
          this.type = type;
          this.info = info;
          break;
        default:
          break;
      }
      this.infoDialog = true;
    },

    convertDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();
      var hr = date.getHours();
      var mn = date.getMinutes();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      if (hr < 10) {
        hr = "0" + hr;
      }
      if (mn < 10) {
        mn = "0" + mn;
      }
      var fulldate =
        year + "-" + month + "-" + dt + " " + hr + ":" + mn + ":00";
      return fulldate;
    },

    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }
    },

    convertToUTCDate() {
      const localDate = new Date();
      const utcDate = new Date(localDate);
      const utcDateTime = new Date(utcDate.toISOString());

      // Get date components
      const year = utcDateTime.getUTCFullYear();
      const month = utcDateTime.getUTCMonth() + 1; // Months are zero-indexed
      const day = utcDateTime.getUTCDate();
      const hours = utcDateTime.getUTCHours();
      const minutes = utcDateTime.getUTCMinutes();
      const seconds = utcDateTime.getUTCSeconds();

      // Format the date
      const formattedDate = `${year}-${this.pad(month)}-${this.pad(
        day
      )} ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;

      return formattedDate;
    },

    pad(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    },

    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },

    agreeOnDelete() {
      this.clicked = true;

      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.data.indexOf(this.selectedObject);
            this.$delete(this.data, index);
            this.text = "Tenant Deleted Succesfully";
            this.snackbar = true;
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.errorDialog = true;
          this.deleteDialog = false;
          this.clicked = false;
        })
        .finally(() => {
          this.loading = false;
          this.deleteDialog = false;
          this.clicked = false;
        });
    },

    showDialog(item) {
      this.valid = true;
      if (item == null) {
        // New tenant
        this.selectedObject = { id: -1 };
        this.name = "";
        this.settings = settings.settings;
        
      } else {
        this.tenant_type = item.type;
        this.selectedObject = item;
        this.name = item.name;
        this.settings = { ...item.settings };
        console.log(this.settings);
      }

      this.dialog = true;
    },

    navigateToInfo(item) {
      this.$router.push(`/tenant/${item.id}/admins`);
    },

    async save() {

      this.clicked = true;
    
      if (!this.$refs.form.validate()) {
        this.clicked = false;
        return false;
      }
      
      this.loading = true;
      const settingsToSave = Object.entries(this.settings).map(([key, value]) => ({
        key, 
        value: String(value) 
      }));
          
      try {
        let tenantId = this.selectedObject.id;
    
        if (tenantId === -1) {
          const date = this.convertToUTCDate();
          const res = await api.add(this.name, date, this.tenant_type || null);
    
          if (res.data.responseCode === 1) {
            tenantId = res.data.data.id; 
            console.log(tenantId);
    
            this.data.push({
              id: tenantId,
              name: this.name,
              created_date: this.convertUTCDateToLocalDate(res.data.data.created_date),
              settings: { ...this.settings },
            });
          } else {
            throw new Error(res.data.responseMessage); 
          }
        } else {
          const res = await api.save(tenantId, this.name, this.tenant_type);
          if (res.data.responseCode === 1) {
            const index = this.data.findIndex((item) => item.id === tenantId);
            if (index !== -1) {
              this.$set(this.data, index, { ...this.data[index], name: this.name });
              this.data[index].settings = this.settings;
            }
          } else {
            throw new Error(res.data.responseMessage); 
          }
        }
    
        
        const saveAllResponse = await api.save_all_settings({
          tenant_id: tenantId,
          data: settingsToSave, 
        });
    
        if (saveAllResponse.data.responseCode !== 1) {
          throw new Error(saveAllResponse.data.responseMessage); 
        }
    
        this.text = tenantId === this.selectedObject.id ? "Tenant updated successfully." : "Tenant added successfully.";
        this.snackbar = true;
      } catch (error) {
        this.text = error.message || error;
        this.snackbar = true;
      } finally {
        this.loading = false;
        this.dialog = false;
        this.clicked = false;
      }
    },

    onPlaceSelected(place){
      console.log(place.name);
      const lat = place.geometry.location.lat();
      const lon = place.geometry.location.lng();
      this.settings.lat = lat;
      this.settings.lon = lon;
      this.settings.location = place.name;
    }
    
    
    
    
  },

  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }

        case "lg": {
          return 4;
        }

        case "xl": {
          return 4;
        }
      }
    },
  },
};
