const SnackbarPlugin = {
    install(Vue) {
        Vue.prototype.$snackbar = new Vue({
        data: {
            visible: false,
            message: '',
            color: 'success',
        },
        methods: {
            show(message, color = 'success') {
                this.message = message;
                if(color === "error"){
                    console.error(message);
                }
                this.color = color;
                this.visible = true;
            },
        },
        });
    },
};

export default SnackbarPlugin;
