import api from "./api.js";
import AddBusDialog from "@/components/dialogs/add_bus_dialog.vue";
var bus_term = localStorage.getItem("bus_term") || "Vehicle"
export default {
  components: {
    AddBusDialog
  },
  data: () => ({
    tenantType: sessionStorage.getItem("tenant_type") ?? null,
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    reference: null,
    clicked: false,
    index: -1,
    bus_term: bus_term,
    text: "",
    search: "",
    id: null,
    json_fields: {
      Id: "id",
      Status: "statusText",
      Reference: "reference",
      "Assigned To": "driverFullName",
    },

    NormalRules: [(v) => !!v || "Field is required"],
    myCurrentData: [],
    ddata: [],
    headers: [
      { text: "Reference", align: "center", value: "reference" },
      { text: "Status", align: "center", value: "drivers[0]" },
      { text: "Actions", value: "actions", align: "center" },
    ],    
  }),
  mounted() {
    window.addEventListener("keyup", this.handleKeyPress);
     api
     .getbuses()
     .then((res) => {
       this.loading = false;
       if (res.data.responseCode === 1) {
         res.data.data.forEach((element) => {
           switch (element.taken) {
             case "1":
               element.statusText = "Assigned";
               element.driverFullName =
                 element.drivers[0].fname + " " + element.drivers[0].lname;
               break;
             case "0":
               element.statusText = "No Assigned";
               element.driverFullName = "N/A";
               break
             default:
               break;
           }
           this.ddata.push(element);
         });
       }
     })
     .catch((error) => {
       this.error = error;
       this.loading = false;
       this.errorDialog = true;
     });
    this.loading = false;
    
  },
  beforeDestroy(){
    window.removeEventListener("keyup", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter") {
        if(this.dialog) this.save();
        if (this.deleteDialog) this.agreeOnDelete();
      }
      
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    showDialog(item) {
      this.valid = true;
      if (item == null) {
        this.index = -1;
        this.selectedObject = { id: -1 };
        this.reference = null;
        this.id = null;
      } else {
        this.index = this.ddata.findIndex((bus) =>bus.id === item.id)
        this.selectedObject = item;
      }
      this.dialog = true;
    },
    onBusAdded(newBus){
      this.ddata.push({
        id: newBus.id,
        reference: newBus.reference,
        statusText: "No Assigned",
        driverFullName: "N/A",
        drivers: [],
      });
    },
    onBusUpdated(newBus, index){
      this.ddata[index].reference = newBus.reference;
    },
    agreeOnDelete() {
      this.clicked = true;
      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.ddata.indexOf(this.selectedObject);
            this.$delete(this.ddata, index);
            this.$snackbar.show(`${bus_term}deleted succesfully.`, "success");
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.errorDialog = true;

          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    closeDialog(){
      this.dialog = false;
    }
  },
};
