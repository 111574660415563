<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="driverForm" v-model="valid" lazy-validation @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <!-- First Name -->
              <v-col cols="6">
                <v-text-field
                  label="First Name"
                  v-model="localDriver.fname"
                  :rules="normalRules"
                  required
                />
              </v-col>

              <!-- Last Name -->
              <v-col cols="6">
                <v-text-field
                  label="Last Name"
                  v-model="localDriver.lname"
                  :rules="normalRules"
                  required
                />
              </v-col>

              <!-- Email -->
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  v-model="localDriver.email"
                  :rules="emailRules"
                  required
                />
              </v-col>

              <!-- Password -->
              <v-col cols="12">
                <v-text-field
                  label="Password"
                  v-model="localDriver.password"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :rules="index === -1 ? passwordRules : [(v) => !v || (v.length >= 4) || 'Minimum 4 characters']"                  
                  :required="index == -1"
                />
              </v-col>

              <!-- Bus combobox + Hourly Rate -->
              <v-col cols="9">
                <v-combobox
                  clearable
                  item-value="id"
                  item-text="reference"
                  v-model="localDriver.bus"
                  :search-input="searchTermBus"
                  :items="[...filteredBuses, { id: -1, reference: 'Create a new ' + busTerm }]"
                  menu-props="auto"
                  :label="busTerm + ' Reference'"
                  hide-details
                  prepend-icon="directions_bus"
                  single-line
                  return-object
                  @change="handleBusChange"
                  :filter="customBusFilter"
                >
                  <template v-slot:item="data">
                    <v-list-item
                      v-bind="data.attrs"
                      v-on="data.on"
                      :class="{ 'add-item': data.item.id === -1 }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.reference }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  type="number"
                  label="Hourly Rate"
                  :prepend-inner-icon="currency"
                  v-model="localDriver.hourly_rate"
                  min="0.00"
                />
              </v-col>

              <!-- Language -->
              <v-col cols="12">
                <v-combobox
                  clearable
                  v-model="localDriver.language"
                  :items="languages"
                  menu-props="auto"
                  label="Language"
                  hide-details
                  prepend-icon="language"
                  single-line
                  return-object
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">Close</v-btn>
          <v-btn class="btn" text @click="index === -1 ? saveDriver() : updateDriver()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <!-- Add bus dialog -->
    <add-bus-dialog
      :bus-term="busTerm"
      @close-bus-dialog="closeAddBusDialog"
      :dialog="addBusDialog"
      @bus-added="onBusAdded"
      :title="'Add ' + busTerm"
    />
  </v-dialog>
</template>

<script>
import AddBusDialog from "./add_bus_dialog.vue";
import axios from "@/plugins/http.service.js";

export default {
  name: "AddDriverDialog",
  components: {
    AddBusDialog,
  },
  props: {
    index:{
      type: Number,
      default: -1
    },
    title: {
      type: String,
    },
    driver: {
      type: Object,
      default: () => ({
        id: null,     
        fname: "",
        lname: "",
        email: "",
        password: "",
        bus: null,
        hourly_rate: null,
        language: null,
      }),
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    driverTerm: {
      type: String,
      default: "Driver",
    },
    busTerm: {
      type: String,
      default: "Bus",
    },
    currency: {
      type: String,
      default: "$",
    },
  },
  data() {
    return {
      localDriver: { ...this.driver },
      valid: false,
      showPassword: false,
      searchTermBus: "",
      buses: [],
      addBusDialog: false,
      newBus: {
        reference: "",
      },
      languages: [],
      normalRules: [
        (v) => (v !== undefined && v !== null && v !== "") || "Field is required",
        (v) => (v && v.length <= 50) || "Must be less than 50 characters",
      ],
      emailRules: [
        (v) => (v !== undefined && v !== null && v !== "") || "Field is required",
        (v) =>
          v === null ||
          v === "" ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      passwordRules: [
        (v) => (v !== undefined && v !== null && v !== "") || "Password is required",
        (v) => (v && v.length >= 4) || "Minimum 4 characters",
      ],
    };
  },
  mounted() {
    // Get languages (example endpoint)
    axios.get("language/get-languages").then((res) => {
      if (res.data.responseCode === 1) {
        this.languages = res.data.data;
      }
    });

    // Load bus list
    this.refreshBuses();
  },
  computed: {
    filteredBuses() {
      const searchTermBus = this.searchTermBus ? this.searchTermBus.toLowerCase() : "";
      const buses = this.buses.filter((bus) =>
        bus?.reference?.toLowerCase().includes(searchTermBus)
      );

      // If user typed something and no existing references match:
      if (searchTermBus && !buses.length) {
        return [...buses, { id: -1, reference: `Create a new ${this.busTerm}` }];
      }
      return buses;
    },
  },
  watch: {
    // Sync prop updates to localDriver
    driver: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.localDriver = { ...newValue }; // Crea una copia indipendente
      },
    },
    dialog(newVal) {
      // When the dialog closes, reset fields so next open is fresh
      if (!newVal) {
        this.resetLocalDriver();
      }
    },
  },
  methods: {
    // Called when user picks "Create a new Bus" item in the combobox
    handleBusChange(selected) {
      if (selected && selected.id === -1) {
        this.newBus.reference = null;
        this.showAddBusDialog();
      }
    },

    customBusFilter(item, queryText) {
      // Always show the "Create new" option
      if (item.id === -1) return true;
      return item.reference.toLowerCase().includes(queryText.toLowerCase());
    },

    closeDialog() {
      this.$emit("close-driver-dialog");
      this.resetLocalDriver();
    },

    /**
     * SAVE (Add) a new driver
     */
    async saveDriver() {
      this.$refs.driverForm.validate();
      if (!this.valid) {
        this.$snackbar.show("Please fill the form correctly", "error");
        return;
      }
      try {
        const { fname, lname, email, password, bus, hourly_rate, language } = this.localDriver;
        const bus_id = bus?.id || null;

        const response = await axios.post("driver/add-driver", {
          fname,
          lname,
          email,
          password,
          bus_id,
          hourly_rate,
          language,
        });
        if (response.data.responseCode === 1) {
          const bus = this.buses.find((bus)=>bus.id == response.data.data.bus_id);
          let driverAdded = response.data.data;
          driverAdded.bus = bus;
          this.$emit("driver-added", driverAdded);
          this.$snackbar.show(`${this.driverTerm} added successfully.`, "success");
          this.closeDialog();
        } else {
          this.$snackbar.show(
            response.data.responseMessage || `Failed to add ${this.driverTerm}.`,
            "error"
          );
        }
      } catch (error) {
        console.error("Error details:", error);
        const errorMessage =
          error?.response?.data?.message || error.message || "An unknown error occurred";
        this.$snackbar.show(`Error adding ${this.driverTerm}: ${errorMessage}`, "error");
      }
    },

    /**
     * UPDATE an existing driver
     * (mirroring the logic from the parent's save() method)
     */
    async updateDriver() {
      // Validate the form first
      this.$refs.driverForm.validate();
      if (!this.valid) {
        this.$snackbar.show("Please fill the form correctly", "error");
        return;
      }

      try {
        // Usually, you'd only require a password if user actually updated it.
        // If you want to handle that logic, do it here (e.g., skip sending password if empty).
        const {
          id,
          fname,
          lname,
          email,
          password,
          bus,
          hourly_rate,
          language,
        } = this.localDriver;

        // If user didn't select a bus, pass null or -1 based on your backend's needs
        const bus_id = bus?.id || null;

        // Example: call your update API
        const response = await axios.post("driver/save-web", {
          id,
          fname,
          lname,
          email,
          password: password || null, // or skip if password is blank
          bus_id,
          hourly_rate,
          language,
        });

        if (response.data.responseCode === 1) {
          // Let parent know that the driver has been updated
          const bus = this.buses.find((bus)=>bus.id == response.data.data.bus_id);
          let driverUpdated = response.data.data;
          driverUpdated.bus = bus;
          this.$emit("driver-updated", driverUpdated, this.index);
          this.$snackbar.show(`${this.driverTerm} updated successfully.`, "success");
          this.closeDialog();
        } else {
          this.$snackbar.show(
            response.data.responseMessage || `Failed to update ${this.driverTerm}.`,
            "error"
          );
        }
      } catch (error) {
        console.error("Error details:", error);
        const errorMessage =
          error?.response?.data?.message || error.message || "An unknown error occurred";
        this.$snackbar.show(`Error updating ${this.driverTerm}: ${errorMessage}`, "error");
      }
    },

    // Dialog for adding a new bus
    closeAddBusDialog() {
      this.addBusDialog = false;
      // Force re-render to "clean" the combobox
      this.showBusCombobox = false;
      this.$nextTick(() => {
        this.showBusCombobox = true;
      });
    },
    showAddBusDialog() {
      this.addBusDialog = true;
      this.newBus = {
        reference: "",
      };
    },
    onBusAdded(newlyAddedBus) {
      this.refreshBuses(newlyAddedBus);
      this.$snackbar.show(`${this.busTerm} added successfully.`, "success");
      this.addBusDialog = false;
    },

    /**
     * Re-fetch the buses to keep the dropdown updated
     */
    refreshBuses(newlyAddedBus = null) {
      axios
        .post("bus/get-buses", { type: 1 })
        .then((res) => {
          if (res.data.responseCode === 1) {
            this.buses = res.data.data;
            // automatically select the newly added bus
            if (newlyAddedBus) {
              this.localDriver.bus = newlyAddedBus;
            }
          }
        })
        .catch((error) => {
          this.$snackbar.show("Error fetching buses: " + error, "error");
        });
    },

    /**
     * Resets the driver form
     */
    resetLocalDriver() {
      this.localDriver = {
        id: null,
        fname: "",
        lname: "",
        email: "",
        password: "",
        bus: null,
        hourly_rate: 0,
        language: null,
      };
    },
  },
};
</script>

<style scoped>
/* Optional styling */
</style>
