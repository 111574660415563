import api from "./api.js"

var driver_term = localStorage.getItem("driver_term") || "Driver";
var trip_term = localStorage.getItem("trip_term") || "Trip";
var warehouse_term = localStorage.getItem("warehouse_term") || "Origin";


export default {
  data: () => ({
    selected_exception_id: null,
    options: {},
    selected_exception: null,
    interval: null,
    driver_filter: '',
    typeOptions: [
      { text: 'All', value: null },
      { text: 'Started Late', value: 'started_late' },
      { text: 'Started Soon', value: 'started_soon' },
      { text: 'Missed destination', value: 'missed_destination' },
      { text: 'Driving time out of range', value: 'driving_time_out_of_range' },
      { text: 'Idle Location', value: 'idle_location' },
    ],  
    typeFilter: null,
    selected_type: null,
    json_fields: {
      'Id': 'id',
      [`${driver_term} Name`]: 'driver_name',
      [`${trip_term} Segment`]: 'trip_origin',
      'Exception Type': 'exception_type',
      'Exception Time': 'exception_time',
    },
    exception_type: new Map([
      ["started_late", [ trip_term +" started late", "mdi-clock-alert"]],
      ["started_soon", [ trip_term +" started soon", "mdi-clock-alert"]],
      ["missed_destination", ["missed destination", "mdi-map-marker-off"]],
      ["missed_destination", ["missed destination", "mdi-map-marker-off"]],
      ["driving_time_out_of_range", ["Driving time out of range", "mdi-bus-clock"]],
      ["idle_location", ["Idle Location", "mdi-pause-octagon"]],
    ]),
    NormalRules: [
      v => !!v || 'Field is required',
    ],
    data: [],
    driver_term: driver_term,
    trip_term: trip_term,
    headers: [
      {
        text: driver_term + ' Name',
        align: 'center',
        value: 'driver_name',
      },
      {
        text: trip_term + ' ' + warehouse_term,
        align: 'center',
        value: 'trip_origin',
      },
      {
        text: 'Exception Type',
        align: 'center',
        value: 'exception_type',
      },
      {
        text: 'Exception Time',
        align: 'center',
        value: 'exception_time',
      },
      {
        text: 'Action',
        align: 'center',
        value: 'actions'
      }
    ],
    infoDialog: false,
    loading: false,
    search: '',
    myCurrentData: [],
    page: 1,      
    pageSize: 10,  
    totalItems: null //TODO: when ready from BE 
  }),
  mounted() {
   
    this.fetch();
    this.interval = setInterval(this.checkSessionStorage, 1000);
  },
  beforeDestroyed(){
    clearInterval(this.interval);
  },
  destroyed(){
    sessionStorage.removeItem("cooriroo_exception_id")
  },
  methods: {
    onPagination(options){
      this.page = options.page;
      this.pageSize = options.itemsPerPage;
      this.fetch();
    },
    checkSessionStorage() {
      const value = sessionStorage.getItem("cooriroo_exception_id");
      if (value !== (this.selected_exception_id !== null ? this.selected_exception_id.toString() : null)) {
          this.selected_exception_id = value !== null ? parseInt(value) : null;
      }
  },
   

  fetch() {
    this.loading = true;
    api.getExceptions(this.page, this.pageSize, this.selected_type ? this.selected_type : "", this.driver_filter ? this.driver_filter : "")
      .then((res) => {
        this.loading = false;
        if (res.data.success) {
          this.totalItems = res.data.pagination?.total;
          this.data = []; 
          res.data.data.forEach((element, index) => {
            this.data.push({
              id: element.id || index,
              driver_name: element.driver ? `${element.driver.fname} ${element.driver.lname}` : 'Unknown Driver',
              trip_origin: element.trip?.warehouse?.title || 'Unknown ' + warehouse_term,
              type: element.type || 'Unknown Type',
              original_start_date: element.trip?.start_date ? new Date(element.trip.start_date).toLocaleString() : 'Unknown Date',
              exception_type: this.exception_type.get(element.type)?.[0] || element.type || 'Unknown Exception',
              exception_time: element.creation_date ? new Date(element.creation_date).toLocaleString() : 'Unknown Time',
              original_start_trip: element.start_date || 'Unknown Start',
              data: element.data || {},
              destinations: element.trip?.destinations?.map(({ destination_name, task }) => ({
                destination_name: destination_name || 'Unknown Destination',
                task: task || null,
              })) || [],
            });
          });
        }
      })
      .catch(() => {
        this.$snackbar.show(
          "Error on fetching the Exceptions", 
          "error"
        );
        this.loading = false;
      });
  },
  
    getRowClass(item) {
      return item.id === this.selected_exception_id ? 'highlight-row' : '';
    },

    getFiltered(e) {
      this.myCurrentData = e;
      // Output the filtered items
    },

    showInfo(item) {
      this.infoDialog = true;
      this.selected_exception = item;
    }
    
  },
};
