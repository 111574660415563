import api from "./api";

//import api from './api.js';
export default {
  data: () => ({
    sentDialog: false,
    sending: false,
    deletedItem: null,
    Email: "",
    fileToSent: null,
    fileTitle: "",
    file: null,
    addDialog: false,
    error_message: "",
    deleteDialog: false,
    editDilaog: false,
    editedItem: null,
    editedTitle: "",

    ddata: [],
    headers: [
      {
        text: "File Title",
        align: "left",
        value: "title",
      },
      {
        text: "File Name",
        align: "left",
        value: "path",
      },
      {
        text: "Actions",
        value: "actions",
        align: "right",
      },
    ],

    imgUrl: process.env.VUE_APP_IMG_URL,
  }),
  mounted() {
    this.getdata();
    this.getFinance();
  },
  computed: {},
  watch: {},

  methods: {
    askEdit(item) {
      this.editDilaog = true;
      this.editedItem = item;
      this.editedTitle = item.title;
    },
    edit() {
      if (this.editedTitle != "") {
        let formData = new FormData();
        formData.append("id", this.editedItem.id);
        formData.append("title", this.editedTitle);

        this.sending = true;
        api.EditFile(formData).then((res) => {
          if (res.data.responseCode == 1) {
            this.ddata[
              this.ddata.indexOf(this.editedItem)
            ].title = this.editedTitle;
            this.editDilaog = false;
            this.editedTitle = "";
          }
        });

        this.sending = false;
      }
    },
    getFinance() {
      api.getFinance().then((res) => {
        if (res.data.responseCode == 1) {
          this.Email = res.data.data;
        } else {
          this.Email = "";
        }
      });
    },
    getdata() {
      let formData = new FormData();
      formData.append("id", this.$route.params.id);
      api.getFile(formData).then((res) => {
        if (res.data.responseCode == 1) {
          this.ddata = res.data.data;
        }
      });
    },
    askSent(item) {
      this.sentDialog = true;
      this.fileToSent = item.path;
    },
    sentFile() {
      this.sending = true;
      let formData = new FormData();
      formData.append("file", this.fileToSent);
      formData.append("Driver_id", this.$route.params.id);
      formData.append("email", this.Email);
      api.sentFile(formData).then((res) => {
        if (res.data.responseCode == 1) {
          this.sentDialog = false;
          this.$snackbar.show(
            "File sent sucessfully",
            "success"
          );
        }
        this.sending = false;
      });
    },
    askDelete(item) {
      this.deleteDialog = true;
      this.deletedItem = item;
    },

    Delete() {
      let formData = new FormData();
      formData.append("id", this.deletedItem.id);
      api.deleteFile(formData).then((res) => {
        if (res.data.responseCode == 1) {
          console.log("deleted");
          this.ddata.splice(this.ddata.indexOf(this.deletedItem), 1);
          this.deleteDialog = false;
        }
      });
    },
    save() {
      if (this.file != null && this.fileTitle != null) {
        let formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("file", this.file);
        formData.append("title", this.fileTitle);
        api.saveFile(formData).then((res) => {
          if (res.data.responseCode == 1) {
            console.log("saved");
            this.getdata();
            this.addDialog = false;
            this.fileTitle = "";
          } else {
            console.log("error", res.data.responseMessage);
            this.$snackbar.show(
              "There was an error when uploading the file: ", 
              "error"
            );
          }
        });
      } else {
        this.$snackbar.show(
          "Please make sure you provide all the require information.", 
          "success"
        );
      }
    },
  },
};
