import api from "./api.js";

var driver_term = localStorage.getItem("driver_term") || "Driver";

export default {
  data: () => ({
    loading: true,
    text: "",
    selectedDrivers: [],
    checkAll: false,
    sendingAll: false,
    sendAllDialog: false,
    picked: "1",
    fdate: null,
    tdate: null,
    fmenu: false,
    tmenu: false,
    show1: false,
    show2: false,
    Email: "",
    myCurrentData: [],
    data: [],
    driver_term: driver_term,
    headers: [
      {
        text: "Select",
        align: "left",
        value: "check",
      },
      {
        text: "Full Name",
        align: "left",
        value: "fullname",
      },
    ],
  }),
  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }
        case "sm": {
          return 4;
        }
        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
  },
  mounted() {
    window.addEventListener("keyup", this.handleKeyPress);
    this.getFinance();
    var myCurrentDate = new Date();
    var myFutureDate = new Date(myCurrentDate);
    myCurrentDate.setMonth(myCurrentDate.getMonth() - 1);
    myCurrentDate.setDate(1);
    myFutureDate.setMonth(myFutureDate.getMonth());
    myFutureDate.setDate(0);
    this.fdate = myCurrentDate.toISOString().substr(0, 10);
    this.tdate = myFutureDate.toISOString().substr(0, 10);
    this.fetch();
  },
  beforeDestroy(){
    window.removeEventListener("keyup", this.handleKeyPress);
  },
  watch: {
    checkAll: {
      handler() {
        this.data.forEach((element) => {
          element.checked = this.checkAll;
        });
      },
    },
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter") {
        if(this.sendAllDialog) this.sendAll();
      }
      
    },
    askSendall() {
      this.sendAllDialog = true;
    },
    sendAll() {
      const emailField = this.$refs.emailField;
    
      if (!emailField.validate()) {
        return; 
      }
    
      this.sendAllDialog = false;
      this.sendingAll = true; 
    
      let temp = {
        fdate: this.fdate,
        tdate: this.tdate,
        email: this.Email,
      };
    
      if (!this.checkAll) {
        this.data.forEach((element) => {
          if (element.checked) {
            this.selectedDrivers.push(element.id);
          }
        });
        temp = { ...temp, selected_Drivers: this.selectedDrivers };
        this.selectedDrivers = []; 
      }
    
      api.sendAllDriversSummary(temp)
        .then((res) => {
          if (res.data.responseCode === 1) {
            this.$snackbar.show("Summary sent successfully!", "success");
          } else if (res.data.responseCode === 2) {
            this.$snackbar.show(`No data for the selected ${this.driver_term} / date range.`, "success");
          } else {
            this.$snackbar.show("Failed to send the summary.", "error");
          }
        })
        .catch(() => {
          // Handle error
          this.$snackbar.show("Error occurred while sending the summary.", "error");
        })
        .finally(() => {
          this.sendingAll = false; // Reset sending state
        });
    },
    
    getFinance() {
      api.getFinance().then((res) => {
        if (res.data.responseCode == 1) {
          this.Email = res.data.data;
        } else {
          this.Email = "";
        }
      });
    },
    fetch() {
      (this.data = []), (this.loading = true);
      api
        .getdrivers(this.fdate, this.tdate)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.data = res.data.data;
          }
        })
        .catch((error) => {
          this.$snackbar.show(error, "error");
          this.loading = false;
          this.errorDialog = true;
        });
    },
  },
};
