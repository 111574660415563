import api from "./api.js";
export default {
  data: () => ({
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    clicked: false,
    search: "",
    isrequired: true,
    noRules: [],
    myCurrentData: [],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length <= 10) || "Password must be less than 10 characters",
      (v) => (v && v.length >= 4) || "Minimum 4 Characters",
    ],

    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    NormalRules: [(v) => !!v || "Field is required"],
    show1: false,
    show2: false,
    email: null,
    fname: null,
    lname: null,
    p1: null,
    p2: null,
    data: [],
    json_fields: {
      Id: "id",
      "First Name": "fname",
      "Last Name": "lname",
      Email: "email",
    },
    headers: [
      {
        text: "First Name",
        align: "center",
        value: "fname",
      },
      {
        text: "Last Name",
        align: "center",
        value: "lname",
      },
      {
        text: "E-mail",
        value: "email",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  mounted() {
    window.addEventListener("keyup", this.handleKeyPress);
    api
      .getadmins()
      .then((res) => {
        this.loading = false;
        if (res.data.responseCode === 1) {
          this.data = res.data.data;
        }
      })
      .catch((error) => {
        this.$snackbar.show(error, "error");
        this.loading = false;
        this.errorDialog = true;
      });
  },
  berforeDestroy(){
    window.removeEventListener("keyup", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter") {
        if(this.dialog) this.save();
        if (this.deleteDialog) this.agreeOnDelete();
      }
      
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    agreeOnDelete() {
      this.clicked = true;
      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.data.indexOf(this.selectedObject);
            this.$delete(this.data, index);
            this.$snackbar.show("User deleted succesfully.", "success");
          } else {
            this.text = res.data.responseMessage;
            this.snackbar = true;
            this.clicked = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$snackbar.show(error, "error");
          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    showDialog(item) {
      this.valid = true;
      this.p1 = null;
      this.p2 = null;

      if (item == null) {
        (this.isrequired = true), (this.selectedObject = { id: -1 });
        this.fname = null;
        this.lname = null;
        this.email = null;
        this.id = null;
        this.p1 = null;
        this.p2 = null;
      } else {
        this.isrequired = false;
        this.selectedObject = item;
        this.fname = item.fname;
        this.lname = item.lname;
        this.email = item.email;
        this.id = item.id;
        this.p1 = null;
        this.p2 = null;
      }
      this.dialog = true;
    },
    save() {
      this.clicked = true;
      if (!this.$refs.form.validate()) {
        return false;
      }
      if (this.password1 != this.password2) {
        this.$snackbar.show("Passwords does not match.", "error");
        this.clicked = false;
        return;
      }
      this.loading = true;
      if (this.selectedObject.id != -1) {
        api
          .save(
            this.selectedObject.id,
            this.fname,
            this.lname,
            this.email,
            this.p1
          )
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              var index = this.data.indexOf(this.selectedObject);
              this.data[index].fname = this.fname;
              this.data[index].lname = this.lname;
              this.data[index].password = this.password1;
              this.data[index].email = this.email.toString().trim();
              this.$snackbar.show("Profile update successfully.", "success")
            } else {
              this.$snackbar.show(res.data.responseMessage, "error");
            }
          })
          .catch((error) => {
            this.$snackbar.show(error, "error");
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      } else {
        api
          .add(this.fname, this.lname, this.email, this.p1)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              this.data.push({
                id: res.data.data.id,
                email: this.email,
                fname: this.fname,
                lname: this.lname,
                created_date: res.data.data.created_date,
              });
              this.$snackbar.show( "User added successfully.", "success");
            } else {
              this.$snackbar.show(res.data.responseMessage, "error");
              this.clicked = false;
            }
          })
          .catch((error) => {
            this.$snackbar.show(error, "error");
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      }
    },
  },
};
