import api from "./api.js";

var driver_term = localStorage.getItem("driver_term") || "Driver";

export default {
  data: () => ({
    tenantType: sessionStorage.getItem("tenant_type") ?? null,
    loading: true,
    json_fields: {
      Id: "id",
      "Driver Name": "driver_id",
      Location: "location",
      Description: "description",
    },
    myCurrentData: [],
    search: "",
    data: [],
    items: [],
    fdate: null,
    tdate: null,
    fmenu: false,
    tmenu: false,
    show1: false,
    carousel_on: false,
    driver_term: driver_term,
    headers: [
      {
        text: driver_term + " Name",
        align: "center",
        value: "driver_id",
      },

      {
        text: "Location",
        align: "center",
        value: "location",
      },
      {
        text: "Description",
        align: "center",
        value: "description",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
      },
    ],
  }),
  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
  },
  mounted() {
    var myCurrentDate = new Date();
    var myFutureDate = new Date();

    this.fdate = myCurrentDate.toISOString().substr(0, 10);
    this.tdate = myFutureDate.toISOString().substr(0, 10);
    this.fetch();

    if ((sessionStorage.getItem("tenant_type") ?? null) === "namat") {
      const headers = [
        {
          text: "Name",
          align: "center",
          value: "name",
        },

        {
          text: "Status",
          align: "center",
          value: "status",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ];
      const data = [
        { name: "Emergency Kits", status: "On-standby" },
        { name: "Disaster Recovery Plans", status: "On-standby" },
        { name: "Rapid Response Teams", status: "On-standby" },
        { name: "Civil Defence Unit", status: "On-standby" },
      ];

      this.headers = headers;
      this.data = data;
    }
  },
  methods: {
    fetch() {
      this.data = [];
      api
        .getaccidents(this.fdate, this.tdate)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            res.data.data.forEach((element) => {
              element.driver_id =
                element.driver.fname + " " + element.driver.lname;

              this.data.push(element);
            });
          }
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
          this.errorDialog = true;
        });
    },
    getFiltered(e) {
      this.myCurrentData = e;
    },
    showcarousel(item) {
      this.items = [];
      api
        .getimages(item.id)
        .then((res) => {
          if (res.data.responseCode === 1) {
            res.data.data.forEach((element) => {
              this.items.push({
                src: this.$imgurl + "accidents/" + element.title,
              });
            });
            this.carousel_on = true;
          } else {
            this.$snackbar("No images available.", "success")
          }
        })
        .catch((error) => {
          this.$show(error, "error");
        });
    },
  },
};
