
import api from "./api.js";
import destinationApi from "../destination/api.js";
import settings from "@/plugins/settings.js";
import draggable from 'vuedraggable';
import AddDriverDialog from '@/components/dialogs/add_driver_dialog.vue';
import AddWarehouseDialog from '@/components/dialogs/add_warehouse_dialog.vue';
import AddTypeDialog from '@/components/dialogs/add_type_dialog.vue';






var trip_term = localStorage.getItem("trip_term") || "Trip";
var driver_term = localStorage.getItem("driver_term") || "Driver";
var destination_term = localStorage.getItem("destination_term") || "Destination";
var currency = settings.fromNameToSymbol(localStorage.getItem("currency") || "Euro");
var warehouse_term = localStorage.getItem("warehouse_term") || "Origin"
var bus_term = localStorage.getItem("bus_term") || "Vechicle"





export default {
  components: {
    draggable,
    AddDriverDialog,
    AddWarehouseDialog,
    AddTypeDialog
  },
  data() {
    return {
      // State Management
      tenantType: sessionStorage.getItem("tenant_type") ?? null,
      valid: false,
      dialog: false,
      deleteDialog: false,
      aiDialog: false,
      bigDialog: false,
      originExceptionDialog: true,
      destinationExceptionDialog: false,
      destinationDialog: false,
      addDriverDialog: false,
      addWarehouseDialog: false,
      addTripTypeDialog: false,

      


      showTooltip: false,
      clicked: false,
      clear: false,
      continuous: false,
      loading: true,
      driverDisable: true,
      tabs: null,
      selectedItem: 1,
      selectedObject: { id: -1 },
      search: "",
      currentDestinationIndex: null,
      fmenu: false,
      tmenu: false,
      show1: false,
      infoDialog: false,
      showStayingTimeRange: false,
      showDrivingTimeRange: false,
      showPassword: false,
      showDriverCombobox: true,
      showWarehouseCombobox: true,
      showTripTypeCombobox: true,
      options:{page: 1, itemsPerPage: 10},
      totalItems: null,
      allowedEdit: true,
      



      // UI Components
      textProps: { "v-model": "msg" },
      dateProps: { color: "primary" },
      timeProps: { format: "24hr", color: "primary", landscape: true },
      title: "",
      trip_term: trip_term,
      driver_term: driver_term,
      destination_term: destination_term,
      warehouse_term: warehouse_term,
      bus_term: bus_term, 
      currency: currency,
      searchTermDriver: '',
      searchTermWarehouse: '',
      searchTermTripType: '',
      dialogTitle: '',

      headers: [
        { text: "Driver Name", value: "driver.fullname", align: "center" },
        { text: warehouse_term, value: "warehouse.title", align: "center" },
        { text: "Type", value: "type0.title", align: "center" },
        { text: "Status", value: "flag", align: "center" },
        { text: "Created On", value: "created_date", align: "center" },
        { text: "Start Date", value: "start_date", align: "center" },
        { text: "Picked On", value: "started_date", align: "center" },
        { text: "Delivered On", value: "ended_date", align: "center" },
        { text: "Accident(s)", value: "accidents", align: "center" },
        { text: "Fine(s)", value: "fines", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],        
      pictures: [],

      // Data Management
      types: [],
      arrivalTimes: [],
      selected_start_date: null,
      currentDestination: null,
      selected_driver: null,
      selected_warehouse: null,
      selected_type: null,
      start_date: null,
      data: [],
      myCurrentData: [],
      warehouses: [],
      drivers: [],
      newDriver: {
        fname: '',
        lname: '',
        email: '',
        password: '',
        bus: null,
        hourly_rate: 0,
        language: null,
      },
      newWarehouse: {
        title: null,  
        lat: null,
        lon: null,   
      },
      newTripType : {
        title: null,
        time_stop: null
      },
    
      fdate: null,
      tdate: null,
      info: null,
      type: null,
      note: "",
      destinations: [],
      students_destination: [],
      destination_id: null,
      trip_destinations: [],
      place: { name: "", lat: null, lon: null },
      departure_time_range: null,
      // Validation Rules
      NormalRules: [(v) => !!v || "Field is required"],
      NumberRules: [
        (v) => v === null || v === undefined || v === "" || v >= 0 || "Please enter a non-negative number",
      ],

      // Others
      snackbarMessage: "",
      json_fields: {
        Id: "id",
        "Driver Name": "driver.fullname",
        Warehouse: "warehouse.title",
        Type: "type.title",
        Status: "flag",
        "Start At": "start_date",
        "Picked On": "started_date",
        "Delivered On": "ended_date",
        "Nb of Accidents": "accidents.length",
        "Nb of Fines": "fines.length",
      },
    };
  },
  created(){
    this.initializeData();
  },
  mounted() {
    window.addEventListener("keyup", this.handleKeyPress);
    if (this.tenantType === "school_bus") {
      this.item_value = "name";
    }
  },
  beforeDestroy(){
    window.removeEventListener("keyup", this.handleKeyPress);
  },
  methods: {
    onTypeAdded(newlyAddedTripType){
      this.types.push(newlyAddedTripType)
      this.selected_type = newlyAddedTripType;
    },
    onInfo(item){
      this.showDialog(item);
      this.dialogTitle = "Show";
      this.allowedEdit = false;
      this.showDrivingTimeRange = true;
      this.showStayingTimeRange = true;
    },
    onPagination(newOptions) {
      this.options = { ...newOptions }; 
      this.fetch();
    },
    addTask(destination) {
      
      if (!destination) {
        console.error('Destination is null or undefined.');
        return;
      }
      if (!destination.tasks) {
        destination.tasks = [];
      }
      destination.tasks.push({ description: '', manager_approval: false, client_approval: false });

    },
    removeTask(destination, taskIndex) {
      if (destination.tasks && destination.tasks.length > taskIndex) {
        destination.tasks.splice(taskIndex, 1);
      }
    },
    saveDestination(index) {
      if (index === -1) {
        this.destinationDialog = false;
        this.originExceptionDialog = false;
        this.destinationExceptionDialog = false;
        return;
      }
    
      const destination = this.trip_destinations[index];
    
      if (destination.tasks && destination.tasks.length > 0) {
        if (destination.tasks.some((task) => {
          return task.description == "";
        }) ){
          this.$snackbar.show("Cannot save destination with pending tasks. Please review the tasks.", "error");
          return;
        }
        
      }
    
      if (!destination.destination_name) {
        this.$snackbar.show("Can't save destination (missing name)", "error");
        return;
      }
    
      this.destinationDialog = false;
      this.originExceptionDialog = false;
      this.destinationExceptionDialog = false;
    },
    closeAddDriverDialog() {
      this.showDriverCombobox = false;
      this.selected_driver = null;
      this.$nextTick(() => {
        this.showDriverCombobox = true;
      });
      this.addDriverDialog = false;
    },
  
    closeAddWarehouseDialog() {
      this.showWarehouseCombobox = false;
      this.newWarehouse.title = null;
      this.$nextTick(() => {
        this.showWarehouseCombobox = true;
      });
      this.addWarehouseDialog = false;
    },
  
    closeAddTripTypeDialog() {
      this.showTripTypeCombobox = false;
      this.newTripType.title = null;
      this.$nextTick(() => {
        this.showTripTypeCombobox = true;
      });
      this.addTripTypeDialog = false;
    },
  
    
    
    customDriverFilter(item, queryText) {
      if (item.id === -1) return true;
      return item.fullname.toLowerCase().includes(queryText.toLowerCase());
    },
    customWarehouseFilter(item, queryText) {
      if (item.id === -1) return true;
      return item.title.toLowerCase().includes(queryText.toLowerCase());
    },

    handleDriverChange(selected) {
      if (selected && selected.id === -1) {
        this.selected_driver = null;
        this.showAddDriverDialog();
        return;
      }

      if (sessionStorage.getItem("tenant_type") == "school_bus"){
        this.fetchStudentDestinations(selected.id)
      }
    },

    fetchStudentDestinations(driver_id) {
      api.getDriverStudents(driver_id).then((res) => {
        if (res.data.responseCode === 1) {
          this.students_destination = res.data.data.map((student) => ({
            student_fname: student.fname,
            student_lname: student.lname,
            destination_name: student.stop_name,
            destination_lat: student.stop_lat,
            destination_lon: student.stop_lon,
            destination_text: student.fname + " " + student.lname + ": " + student.stop_name,
            destination_id: student?.destination?.id
          }));
        }
      });
    },

      selectDestinationSchoolBus(selectedDestination) {  
        if (selectedDestination && selectedDestination.destination_name) {
          this.currentDestination.destination_id = selectedDestination.destination_id;
          this.currentDestination.destination_name = selectedDestination.destination_name;
          this.currentDestination.destination_lat = selectedDestination.destination_lat;
          this.currentDestination.destination_lon = selectedDestination.destination_lon;
          this.currentDestination.student_fname = selectedDestination.student_fname;
          this.currentDestination.student_lname = selectedDestination.student_lname;
        } else {
          this.currentDestination.destination_id = null;
          this.currentDestination.destination_name = null;
          this.currentDestination.destination_lat = null;
          this.currentDestination.destination_lon = null;
          this.currentDestination.student_fname = null;
          this.currentDestination.student_lname = null;
        }
        console.log(this.currentDestination.destination_id);
        this.trip_destinations[this.currentDestinationIndex] = this.currentDestination;
      },
      

    handleWarehouseChange(selected) {
      if (selected && selected.id === -1) {
        this.selected_warehouse = null;
        this.showAddWarehouseDialog();
      }
    },

    handleTripTypeChange(selected) {
      if (selected && selected.id === -1) {
        this.selected_type = null;
        this.showAddTripTypeDialog();
      }
    },


    showAddTripTypeDialog(){
      this.addTripTypeDialog = true;
      this.newTripType = {
        title: null,
        time_stop: null,
      };
    },

    showAddWarehouseDialog(){
      this.addWarehouseDialog = true;
      this.newWarehouse = {
        title: null,
        lat: null,
        lon: null,
      }
    },

    onWarehouseAdded(newlyAddedWarehouse){
      this.warehouses.push(newlyAddedWarehouse);
      this.selected_warehouse = newlyAddedWarehouse;
    },
    
    showAddDriverDialog() {
      this.addDriverDialog = true;
    },
    async onDriverAdded(newlyAddedDriver){
      await this.updateDrivers();
      const newDriver = {
        id: newlyAddedDriver.id,
        fullname: `${newlyAddedDriver.fname} ${newlyAddedDriver.lname}`,
        email: newlyAddedDriver.email,
        bus : newlyAddedDriver.bus,
        hourly_rate: newlyAddedDriver.hourly_rate,
        language: newlyAddedDriver. language
      };
      this.drivers.push(newDriver);
      this.selected_driver = newDriver;
    },

    handleKeyPress(event) {
      if (event.key === "Enter") {
        switch (true) {
          case this.dialog:
            this.save();
            return;
          case this.deleteDialog:
            this.agreeOnDelete();
            return;
          default:
            break;
        }
      }
    },
    
    initializeData() {
      const fromDate = new Date();
      fromDate.setDate(1); 
      const toDate = new Date();
      toDate.setDate(toDate.getDate()+ 1)
      this.fdate = fromDate.toISOString().substr(0, 10);
      this.tdate = toDate.toISOString().substr(0, 10);

      api.gettypes().then(this.handleGetTypes).catch(this.handleError);
      api.getwarehouses().then(this.handleGetWarehouses).catch(this.handleError);
    },
    handleGetTypes(res) {
      if (res.data.responseCode === 1) {
        this.types = res.data.data;
      }
    },
    handleGetWarehouses(res) {
      if (res.data.responseCode === 1) {
        this.warehouses = res.data.data.map((element) => ({
          id: element.id,
          title: element.title,
        }));
      }
    },
    handleError(error) {
      this.error = error;
      this.errorDialog = true;
    },
    async fetch() {
      this.loading = true;
      const {page, itemsPerPage} = {...this.options};

      try {
        const res = await api.gettrips(this.fdate, this.tdate, page, itemsPerPage);
        if (res.data.responseCode === 1) {
          this.totalItems = res.data.data.pagination.total_items;
          this.data = res.data.data.trips.map((element) => {
            const driver = element.driver
              ? {
                  fullname: `${element.driver.fname} ${element.driver.lname}`,
                  id: element.driver.id,
                }
              : { fullname: "N/A", id: -1 };
            const warehouse = {
              title: element.warehouse.title,
              id: element.warehouse.id,
            };
            const flag = this.getFlagStatus(element.flag, element.ended_date);
            const status = this.getStatus(element.started_date, element.start_date);
            

            return {
              ...element,
              driver,
              warehouse,
              flag,
              status,
              started_date: this.formatDate(element.started_date),
              created_date: this.formatDate(element.created_date),
              start_date: this.formatDate(element.start_date),
              ended_date: this.formatDate(element.ended_date),
            };
          });
          this.loading = false;
        }

        const destRes = await destinationApi.getdestinations();
        if (destRes.data.responseCode === 1) {
          this.destinations = destRes.data.data;
        }
      } catch (error) {
        this.$snackbar.show("Error fetching data: " + error, "error")
      } finally {
        this.loading = false;
      }
    },
    getFlagStatus(flag, ended_date) {
      if (flag === 0) {
        return "Not Picked";
      } else if (ended_date) {
        return "Delivered";
      } else {
        return "Picked";
      }
    },
    getStatus(started_date, start_date) {
      if (started_date != null) {
        return new Date(started_date) > new Date(start_date) ? 1 : 2;
      } else {
        return 0;
      }
    },
    handleIconClick(from, index = null) {
      if (from === "origin") {
        this.destinationDialog = false;
        this.originExceptionDialog = true;
        this.currentDestination = null;
        this.currentDestinationIndex = null;
      } else if (index !== null && this.trip_destinations[index]) {
        this.currentDestinationIndex = index;
        this.currentDestination = this.trip_destinations[index];
        this.destinationExceptionDialog = true;
        this.destinationDialog = false;
        this.originExceptionDialog = false;
      } else {
        console.error("Destination not found or invalid index provided.");
      }
    },
    parseDate(dateString) {

      if (!dateString) return null;
    

      const [datePart, timePartWithAmPm] = dateString.split(", ");
      if (!datePart || !timePartWithAmPm) return null;
    
      const [monthStr, dayStr, yearStr] = datePart.split("/"); 
      const month = parseInt(monthStr, 10);
      const day   = parseInt(dayStr, 10);
      const year  = parseInt(yearStr, 10);
    

      const [timePart, ampm] = timePartWithAmPm.split(" ");
      if (!timePart || !ampm) return null;
    
      const [hoursStr, minutesStr, secondsStr] = timePart.split(":"); 
      let hours   = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);
      const seconds = parseInt(secondsStr, 10);

      if (ampm === "PM" && hours < 12) {
        hours += 12;
      } else if (ampm === "AM" && hours === 12) {
        hours = 0;
      }
    
      const parsedDate = new Date(year, month - 1, day, hours, minutes, seconds);
      return parsedDate;
    },    
    getFiltered(e) {
      this.myCurrentData = e;
    },
    back() {
      this.$nextTick(() => {
        this.tabs = "tab-1";
      });
    },
    show(item) {
      this.pictures = item.pictures.map((element) => ({
        src:
          this.type === 0
            ? `${this.$imgurl}fines/${element.title}`
            : `${this.$imgurl}accidents/${element.title}`,
      }));
      this.$nextTick(() => {
        this.tabs = "tab-2";
      });
    },
    focus(type, info) {
      this.$nextTick(() => {
        this.tabs = "tab-1";
      });
      this.type = type;
      this.info = info;
      this.infoDialog = true;
    },
    formatDate(dateInput) {
      if (!dateInput) return null;
    
      let date;
    
      if (typeof dateInput === 'string') {
        const [datePart, timePart] = dateInput.split(' ');
        if (!datePart || !timePart) return null;
    
        const [year, month, day] = datePart.split('-').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
    
        date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
      } else if (dateInput instanceof Date) {
        date = dateInput;
      } else {
        return null;
      }
    
      return date.toLocaleString();
    },
    
    pad(number) {
      return number < 10 ? "0" + number : number;
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    async agreeOnDelete() {
      this.clicked = true;
      this.loading = true;
      try {
        const res = await api.archive(this.selectedObject.id);
        if (res.data.responseCode === 1) {
          this.deleteDialog = false;
          this.data = this.data.filter((item) => item.id !== this.selectedObject.id);
          this.$snackbar.show(`${trip_term} deleted successfully.`, "success");
        } else {
          this.$snackbar.show(res.data.responseMessage || `Failed to delete ${trip_term}`, "error");
        }
      } catch (error) {
        this.$snackbar.show(`Error deleting ${trip_term}: ` + error, "error");
      } finally {
        this.loading = false;
        this.clicked = false;
      }
    },
    async showAiDialog() {
      this.aiDialog = true;
    },
    async showDialog(item) {
      this.allowedEdit = true;
      this.valid = true;
      if (item == null) {
        this.dialogTitle = "Add"
        this.resetFormData();
      } else {
        this.dialogTitle = "Update"
        this.populateFormData(item);
        this.calculateArrivalTimes();
      }
      this.arrivalTimes.push(["origin_start_time", new Date(this.selected_start_date)]);
      this.dialog = true;
    },
    resetFormData() {
      this.selectedObject = { id: -1 };
      this.selected_driver = null;
      this.selected_warehouse = null;
      this.selected_type = null;
      this.id = null;
      this.selected_start_date = new Date();
      this.place = { name: null, lat: null, lon: null };
      this.trip_destinations = [];
      this.note = null;
      this.departure_time_range = null;
    },
    populateFormData(item) {
      this.selectedObject = item;
      this.selected_warehouse = {
        id: item.warehouse.id,
        title: item.warehouse.title,
      };
      this.selected_driver = {
        id: item.driver.id,
        fullname: item.driver.fullname,
      };
      this.selected_type = {
        id: item.type0.id,
        title: item.type0.title,
      };
      this.id = item.id;
      this.selected_start_date = this.parseDate(item.start_date);
      this.note = item.note;
      this.destination_id = item.destination_id;
      this.place = {
        name: item.destination_name,
        lat: item.destination_lat,
        lon: item.destination_lon,
      };
      this.trip_destinations = item.destinations.map((des) => ({
        destination_id: des.destination_id,
        destination_lat: des.destination?.lat || des.destination_lat,
        destination_lon: des.destination?.lon || des.destination_lon,
        destination_name: des.destination_name,
        tasks: des.tasks.map((task) =>{
          return {
            description: task.description,
            client_approval: task.client_approval,
            manager_approval: task.manager_approval,
            ...task
            
          }}).sort((task_a, task_b) => task_a.priority - task_b.priority),
        staying_time: des.staying_time ?? null,
        driving_time: des.driving_time ?? null,
        staying_time_range: des.staying_time_range ?? null,
        driving_time_range: des.driving_time_range ?? null,
      }));
      this.departure_time_range = item.departure_time_range;
      if(this.tenantType === "school_bus"){
        this.fetchStudentDestinations(this.selected_driver.id)
      }
    },
    async save() {
      this.clicked = true;

      let ok = true;
      this.trip_destinations.forEach((destination) => {
        if (!destination.destination_name) {
          ok = false;
        }
        
        destination.staying_time = destination.staying_time != null ? parseInt(destination.staying_time, 10) : null;
        destination.driving_time = destination.driving_time != null ? parseInt(destination.driving_time, 10) : null;
        destination.staying_time_range = destination.staying_time_range != null ? parseInt(destination.staying_time_range, 10) : null;
        destination.driving_time_range = destination.driving_time_range != null ? parseInt(destination.driving_time_range, 10) : null;
      });

      if (!this.$refs.form.validate() || !ok) {
        const message = ok
        ? "Please fill the form correctly"
        : "Fill the " + destination_term + " correctly";
        this.$snackbar.show(message, "error");
        this.clicked = false;
        return;
      }

      this.loading = true;
      try {
        let success = false;
        this.departure_time_range = this.departure_time_range ? parseInt(this.departure_time_range) : null;
        if (this.selectedObject.id !== -1) {
          success = await this.updateTrip();
        } else {
          success = await this.addTrip();
        }
        if (success) {
          this.dialog = false;
          this.resetData();
          this.fetch();
        }
      } catch (error) {
        this.$snackbar.show(`Error saving ${trip_term}: ` + error, "error")
      } finally {
        this.loading = false;
        this.clicked = false;
      }
    },


    async updateTrip() {
    const res = await api.save(
      this.id,
      this.selected_driver.id,
      this.selected_warehouse.id,
      this.selected_type.id,
      this.convertToUTCDate(this.selected_start_date),
      this.note,
      this.trip_destinations,
      this.departure_time_range
    );
    if (res.data.responseCode === 1) {
      const index = this.data.findIndex((item) => item.id === this.selectedObject.id);
      if (index !== -1) {
        this.data[index] = {
          ...this.data[index],
          driver: {
            id: this.selected_driver.id,
            fullname: this.selected_driver.fullname,
          },
          warehouse: {
            id: this.selected_warehouse.id,
            title: this.selected_warehouse.title,
          },
          type0: this.selected_type,
          start_date: this.formatDate(this.selected_start_date),
          departure_time_range: this.departure_time_range,
          trip_destinations: this.trip_destinations,
        };
      }
      this.$snackbar.show(`${trip_term} updated successfully.`, "success")
      return true;
    } else {
      this.$snackbar.show(res.data.responseMessage || `Failed to update ${trip_term}.`, "error")
      return false;
    }
  },
  async addTrip() {
    const res = await api.add(
      this.selected_driver.id,
      this.selected_warehouse.id,
      this.selected_type.id,
      this.convertToUTCDate(this.selected_start_date),
      this.note,
      this.trip_destinations,
      this.departure_time_range
    );
    if (res.data.responseCode === 1) {
      this.data.push({
        id: res.data.data.id,
        driver: {
          id: res.data.data.driver_id,
          fullname: this.selected_driver.fullname,
        },
        warehouse: {
          id: res.data.data.warehouse_id,
          title: this.selected_warehouse.title,
        },
        type0: this.selected_type,
        start_date: this.formatDate(this.selected_start_date),
        flag: "Not Picked",
        created_date: this.formatDate(new Date()),
        ended_date: null,
        started_date: null,
        accidents: [],
        fines: [],
      });
      this.$snackbar.show(`${trip_term} added succesfully`, "success");
      return true;
    } else {
      this.$snackbar.show(res.data.responseMessage || `Failed to add ${trip_term}.`, "error");
      return false;
    }
  },
    onPlaceSelected(place, index) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      this.trip_destinations[index] = {
        ...this.trip_destinations[index],
        destination_id: null,
        destination_name: place.formatted_address,
        destination_lat: latitude,
        destination_lon: longitude,
      };
      this.currentDestination = this.trip_destinations[index];
    },
    onPlaceInputChange(place, index) {
      this.trip_destinations[index] = {
        ...this.trip_destinations[index],
        destination_name: place || null,
        destination_lat: null,
        destination_lon: null,
      };
    },
    closeDialog() {
      this.dialog = false;
      this.resetData();
    },
    addDestination() {
      this.currentDestination = {
        destination_id: null,
        destination_name: null,
        destination_lat: null,
        destination_lon: null,
        tasks: [],
        staying_time: null,
        driving_time: null,
        staying_time_range: null,
        driving_time_range: null,
      };
      this.destinationDialog = true;
      this.trip_destinations.push(this.currentDestination);
      this.currentDestinationIndex = this.trip_destinations.length - 1;
    },
    removeDestination(index) {
      this.trip_destinations.splice(index, 1);
      this.calculateArrivalTimes();
      if (this.trip_destinations.length === 0) {
        this.destinationDialog = false;
        this.currentDestination = null,
        this.currentDestinationIndex = null;
      } else {
        this.currentDestinationIndex = index > 0 ? index - 1 : 0;
        this.currentDestination = this.trip_destinations[this.currentDestinationIndex];
      }
    },
    updateDestinationName(destination) {
      if (!destination.destination_id) {
        destination.destination_name = "";
        this.clear = true;
        return;
      }
      this.clear = false;

      const selectedDestination = this.destinations.find(
        (dest) => dest.id === destination.destination_id
      );

      if (selectedDestination) {
        destination.destination_name = selectedDestination.name;
      }
    },
    editDestination(destination, index) {
      this.originExceptionDialog = false;
      this.destinationDialog = true;
      this.currentDestination = destination;
      this.currentDestinationIndex = index;
    },
    resetData() {
      this.destinationDialog = false;
      this.currentDestination = {tasks: []};
      this.currentDestinationIndex = null;
      this.selected_type = null;
      this.destination_id = null;
      this.note = "";
      this.place = { name: "", lat: null, lon: null };
      this.showDrivingTimeRange = false;
      this.showStayingTimeRange = false;
      this.destinationExceptionDialog = false;
      this.originExceptionDialog = true;

    },
    async cloneDialog(item) {
      this.allowedEdit = true;
      this.valid = true;
      this.dialogTitle = "Update";
      this.populateFormData(item);
      this.calculateArrivalTimes();
      this.arrivalTimes.push([
        "origin_start_time",
        new Date(this.selected_start_date),
      ]);
      this.dialog = true;
      this.selectedObject = { id: -1 };
    },
    calculateArrivalTimes() {
      this.arrivalTimes = [];
      let startTime = new Date(this.selected_start_date);
      this.arrivalTimes.push(["origin_start_time", new Date(startTime)]);

      this.trip_destinations.forEach((destination, index) => {
        const drivingTimeMinutes = parseInt(destination.driving_time, 10);
        const stayingTimeMinutes = parseInt(destination.staying_time, 10);

        if (
          !isNaN(drivingTimeMinutes) &&
          drivingTimeMinutes !== 0 &&
          !isNaN(stayingTimeMinutes) &&
          stayingTimeMinutes !== 0
        ) {
          startTime = new Date(startTime.getTime() + drivingTimeMinutes * 60000);
          startTime = new Date(startTime.getTime() + stayingTimeMinutes * 60000);
          this.arrivalTimes.push([index, new Date(startTime)]);
        } else {
          this.arrivalTimes.push([index, null]);
        }
      });

      this.continuous = this.arrivalTimes.slice(0, -1).every((item) => item[1] !== null);
    },
    getExpectedArrivalTime(index, stayingTime) {
      const arrivalTime = this.arrivalTimes[index + 1][1];
      if (arrivalTime && !isNaN(stayingTime)) {
        const adjustedArrivalTime = new Date(arrivalTime.getTime() - stayingTime * 60000);
        return adjustedArrivalTime.toLocaleString();
      }
      return "N/A";
    },
    async updateDrivers() {
      try {
        const res = await api.getdrivers();
        if (res.data.responseCode === 1) {
          this.drivers = res.data.data
            .filter((element) => element.archived !== 1)
            .map((element) => ({
              id: parseInt(element.id),
              fullname: `${element.fname} ${element.lname}`,
            }));
          this.driverDisable = false;
        }
      } catch (error) {
        this.$snackbar.show(error, "error")
      }
    },
    convertToUTCDate(date) {
      const utcDate = new Date(date || new Date());
      return utcDate.toISOString().replace("T", " ").substr(0, 19);
    },
    toggleStayingTimeRange() {
      if (this.currentDestination) this.showStayingTimeRange = !this.showStayingTimeRange;
    },
    toggleDrivingTimeRange() {
      if (this.currentDestination) {
        this.showDrivingTimeRange = !this.showDrivingTimeRange;
      }
    },
  },
  watch: {
    trip_destinations: {
      handler() {
        this.calculateArrivalTimes();
      },
      deep: true,
    },
    selected_start_date() {
      this.calculateArrivalTimes();
      this.updateDrivers();
    },
  },
  computed: {
    
      filteredDrivers() {
        const searchTermDriver = this.searchTermDriver ? this.searchTermDriver.toLowerCase() : '';  // Ensure searchTerm is a string
        const drivers = this.drivers.filter(driver => driver?.fullname?.toLowerCase().includes(searchTermDriver)); // Add null checks
    
        if (searchTermDriver && !drivers.length) {
          return [...drivers, { id: -1, fullname: `Create a new ${this.driver_term}` }];
        }
        return drivers;
      },

      filteredWarehouses() {
        const searchTermWarehouse = this.searchTermWarehouse ? this.searchTermWarehouse.toLowerCase() : '';  // Ensure searchTermWarehouse is a string
        const warehouses = this.warehouses.filter(warehouse => warehouse?.title?.toLowerCase().includes(searchTermWarehouse)); // Add null checks
    
        if (searchTermWarehouse && !warehouses.length) {
          return [...warehouses, { id: -1, title: `Create a new ${warehouse_term}` }];
        }
        return warehouses;
      },

      filteredTripTypes() {
        const searchTermTripType = this.searchTermTripType ? this.searchTermTripType.toLowerCase() : '';  // Ensure searchTermTripType is a string
        const trip_types = this.types.filter(type => type?.title?.toLowerCase().includes(searchTermTripType)); // Add null checks
    
        if (searchTermTripType && !trip_types.length) {
          return [...trip_types, { id: -1, title: `Create a new ${trip_term} type` }];
        }
        return trip_types;
      },    
    
    
    Column() {
      const sizeMap = {
        xs: 12,
        sm: 4,
        md: 4,
        lg: 4,
        xl: 4,
      };
      return sizeMap[this.$vuetify.breakpoint.name] || 12;
    },
  },
};
