import axios from "@/plugins/http.service.js";

export default {
  gettypes() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.post(`type/get-types?tenant_id=${tenant_id}`);
  },

  gettrips(page = 1, pageSize = 10) {
    return axios.get(`trip-schedule/get-scheduled-trips?page=${page}&page_size=${pageSize}`);
  },
  getDriverStudents(driver_id) {
    return axios.get(`driver/get-driver-students?id=${driver_id}`);
  },
  add(
    driver_id,
    warehouse_id,
    type,
    start_date,
    note,
    trip_destinations,
    end_date,
    repeat_type,
    repeat_interval,
    days_of_week,
    departure_time_range
  ) {
    var data = {
      driver_id: driver_id,
      warehouse_id: warehouse_id,
      type: type,
      start_date: start_date,
      note,
      destinations: trip_destinations,
      end_date,
      repeat_type,
      repeat_interval,
      days_of_week,
      departure_time_range
    };
    return axios.post("trip-schedule/add-trip-schedule", data);
  },

  save(
    id,
    driver_id,
    warehouse_id,
    type,
    start_date,
    note,
    trip_destinations,
    end_date,
    repeat_type,
    repeat_interval,
    days_of_week,
    departure_time_range
  ) {
    var data = {
      id: id,
      driver_id: driver_id,
      warehouse_id: warehouse_id,
      type: type,
      start_date: start_date,
      note,
      destinations: trip_destinations,
      end_date,
      repeat_type,
      repeat_interval,
      days_of_week,
      departure_time_range
    };
    return axios.post("trip-schedule/edit-trip-schedule", data);
  },

  getdrivers() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.get(`driver/get-drivers?tenant_id=${tenant_id}`);
  },

  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("trip/archive", data);
  },

  delete(id) {
    return axios.post(`trip-schedule/delete-trip-schedule?id=${id}`);
  },

  getwarehouses() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.post(`warehouse/get-warehouse?tenant_id=${tenant_id}`);
  },
  getlanguages(){
    
    return axios.post(`language/get-languages?`);
  },
};
