import api from "./api.js";
// import VueGoogleAutocomplete from "vue-google-autocomplete";

var destination_term = localStorage.getItem("destination_term") || "Destination";
export default {
  // components: { VueGoogleAutocomplete },
  data: () => ({
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    search: "",
    tabs: null,
    selectedItem: 1,
    clicked: false,
    googleLoaded: false,
    place: null,
    id: null,
    name: "",
    lat: null,
    lon: null,
    info: [],
    destination_term: destination_term,
    textProps: {
      "v-model": "msg",
    },
    NormalRules: [(v) => !!v || "Field is required"],
    types: [],
    selected_start_date: null,
    data: [],
    type: null,
    myCurrentData: [],
    infoDialog: false,
    fmenu: false,
    tmenu: false,
    show1: false,
    json_fields: {
      Id: "id",
      Name: "name",
    },
    headers: [
      {
        text: "Name",
        value: "name",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  async mounted() {
    window.addEventListener("keyup", this.handleKeyPress);
    // const apiKey = process.env.VUE_APP_GOOGLE_MAPS_KEY;
    // await this.loadGoogleMaps(apiKey);
    await this.fetch();
  },
  beforeDestroy(){
    window.removeEventListener("keyup", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter") {
        if(this.dialog) this.save();
        if(this.deleteDialog) this.agreeOnDelete();
      }
      
    },
    async fetch() {
      this.data = [];
      api
        .getdestinations()
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.data = res.data.data;
          }
        })
        .catch((error) => {
          this.$snackbar.show(error, "error");
          this.loading = false;
          this.errorDialog = true;
        });
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    back() {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
    },
    show(item) {
      this.pictures = [];
      item.pictures.forEach((element) => {
        switch (this.type) {
          case 0:
            this.pictures.push({
              src: this.$imgurl + "fines/" + element.title,
            });
            break;
          case 1:
            this.pictures.push({
              src: this.$imgurl + "accidents/" + element.title,
            });
            break;
          default:
            break;
        }
      });
      this.$nextTick(() => {
        this.tabs = "tab-" + 2;
      });
    },
    focus(type, info) {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
      this.info = [];
      switch (type) {
        case 0: //Fines
          this.type = type;
          this.info = info;
          break;
        case 1: // Accidents
          this.type = type;
          this.info = info;
          break;
        default:
          break;
      }
      this.infoDialog = true;
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    agreeOnDelete() {
      this.clicked = true;

      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.data.indexOf(this.selectedObject);
            this.$delete(this.data, index);
            this.$snackbar.show("Destination Deleted Succesfully", "success");
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.errorDialog = true;
          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    showDialog(item) {
      this.valid = true;
      if (item == null) {
        this.selectedObject = { id: -1 };
        this.name = "";
        this.id = null;
      } else {
        this.selectedObject = item;

        this.id = item.id;
        this.name = item.name;
      }

      this.dialog = true;
    },
    save() {
      const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");
      this.clicked = true;
      if (!this.$refs.form.validate()) return false;
      this.loading = true;

      if (this.place === null) {
        this.lat = null;
        this.lon = null;
      }

      if (this.selectedObject.id != -1) {
        api
          .save(this.id, this.name, this.lat, this.lon)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              var index = this.data.indexOf(this.selectedObject);
              this.data[index].name = this.name;
              this.$snackbar.show("Destination updated successfully.", "success");
            } else {
              this.$snackbar.show(res.data.responseMessage, "success");
            }
          })
          .catch((error) => {
            this.$snackbar.show(error, "success");
            this.loading = false;
            this.dialog = false;
          });
      } else {
        api
          .add(this.name, tenant_id, this.lat, this.lon)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;

            if (res.data.responseCode === 1) {
              this.data.push({
                id: res.data.data.id,
                name: this.name,
              });
              this.$snackbar.show("Destination added successfully.", "success");
            } else {
              this.$snackbar.show(res.data.responseMessage, "success");
            }
          })
          .catch((error) => {
            this.$snackbar.show(error, "error");
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      }
    },
    onPlaceSelected(place) {
      console.log("Selected place:", place);
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      this.name = place.name;
      this.lat = latitude;
      this.lon = longitude;
      this.place = place;
    },
  },
  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
  },
};
