import axios from "@/plugins/http.service.js";

export default {
  gettypes() {
    return axios.post(`type/get-types`);
  },
  gettrips(fdate, tdate, page, pageSize) {
    var data = {
      fdate: fdate,
      tdate: tdate,
    };
    return axios.post(`trip/get-all-trips?page=${page}&page_size=${pageSize}`, data);
  },
  getDriverStudents(driver_id) {
    return axios.get(`driver/get-driver-students?id=${driver_id}`);
  },
  add(driver_id, warehouse_id, type, start_date, note, trip_destinations, departure_time_range) {
    var data = {
      driver_id: driver_id,
      warehouse_id: warehouse_id,
      type: type,
      start_date: start_date,
      note,
      destinations: trip_destinations,
      departure_time_range: departure_time_range
    };
    return axios.post("trip/add-trip", data);
  },
  save(id, driver_id, warehouse_id, type, start_date, note, trip_destinations, departure_time_range) {
    var data = {
      id: id,
      driver_id: driver_id,
      warehouse_id: warehouse_id,
      type: type,
      start_date: start_date,
      note,
      destinations: trip_destinations,
      departure_time_range: departure_time_range
    };
    return axios.post("trip/save", data);
  },
  getdrivers() {
    return axios.get(`driver/get-drivers`);
  },
  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("trip/archive", data);
  },

  getwarehouses() {
    return axios.post(`warehouse/get-warehouse`);
  },

  getbuses() {
    var data = {
      type: 1,
    };
    return axios.post(`bus/get-buses`, data);
  },
};
