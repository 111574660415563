import studentApi from "./api.js"; 
  
  export default {
    name: "StudentPage",
    data() {
      return {
        // Tabella
        headers: [
          { text: "First Name", value: "fname", align: "center"},
          { text: "Last Name", value: "lname", align: "center"},
          { text: "Driver", value: "driver.fname", sortable: false, align: "center" },
          { text: "Actions", value: "actions", sortable: false, align: "center" },
        ],
        requiredRule: [ (v) => !!v || "Password is required"],
        students: [],
        myCurrentData: [], 
        guardians: [],
        search: "",
        Column: 12, 
        loading: false,
        dialog: false,
        deleteDialog: false,
        valid: false,
        clicked: false,
        readonly: false,
        selectGuardian: [], 
        currentGuardianIndex: null, 
        selectedGuardian: null,

        selectedStudent: {
          id: -1,
          fname: "",
          lname: "",
          stop_name: "",
          stop_lat: null,
          stop_lon: null,
          guardians: [],
        },
        selectedDriver: null,
        drivers: [],
  
  
  
        NormalRules: [(v) => !!v || "Required field"],
        EmailRules: [
          (v) => !!v || "Required field",
          (v) => /.+@.+\..+/.test(v) || "Email not valid",
        ],
      };
    },
    created() {
      this.getStudents();
      this.getDrivers();
      this.getGuardians();
    },
    methods: {
      getFiltered(items) {
        this.myCurrentData = items;
      },
  
      async getGuardians(){
        try {
          const { data } = await studentApi.getGuardians();
          if (data.success) {
            this.guardians = data.guardians.map((guardian)=>{
              return{
                ...guardian,
                full_name: guardian.fname + " " + guardian.lname
              }
            });
          } else {
            this.$snackbar.show(data.message || "Error on loading", "error");
          }
        } catch (err) {
          this.$snackbar.show(err.message, "error");
        }
      },
      async getStudents() {
        this.loading = true;
        try {
          const { data } = await studentApi.getStudents();
          if (data.success) {
            this.students = data.data;
          } else {
            this.$snackbar.show(data.message || "Error on loading", "error");
          }
        } catch (err) {
          this.$snackbar.show(err.message, "error");
        }
        this.loading = false;
      },
  
      async getDrivers() {
          studentApi.getDrivers().then((res)=>{
            if(res.status == 200){
              this.drivers = res.data.data;
            }else{
              this.$snackbar.show(res.data.responseMessage || "Error during driver loading");
            }
          });
      },
  
      showDialog(item, type = "edit") {
        this.readonly = false;
        if (!item) {
          this.selectedStudent = {
            id: -1,
            fname: "",
            lname: "",
            stop_name: "",
            stop_lat: null,
            stop_lon: null,
            guardians: [],
          };
          this.selectedDriver = null;
        } else {
          if(type == "info"){
            this.readonly = true;
          }
          this.selectedStudent = JSON.parse(JSON.stringify(item));
          this.selectedDriver = item.driver;
        }
        this.dialog = true;
      },
  
      async saveStudent() {
        this.clicked = true;
  
        this.selectedStudent.driver_id = this.selectedDriver
          ? this.selectedDriver.id
          : null;
  
        this.selectedStudent.stop_lat = parseFloat(this.selectedStudent.stop_lat, 10) ?? null;
        this.selectedStudent.stop_lon = parseFloat(this.selectedStudent.stop_lon, 10) ?? null;

        try {
          /*if (this.selectedStudent.id === -1) {
            const { data } = await studentApi.addStudent(this.selectedStudent);
            if (data.success !== false) {
              this.$snackbar.show(data.message || "Student added succesfully");
            } else {
              console.error(data);
              this.$snackbar.show(data.message || "Error during insert");
            }
          } else {
            const { data } = await studentApi.editStudent(
              this.selectedStudent,
              this.selectedStudent.id
            );
            if (data.success !== false) {
              this.$snackbar.show(data.message || "Student updated succesfully");
            } else {
              this.$snackbar.show(data.message || "Error during update");
            }
          }*/

            // when edit is correctly implemented remove this section nd the comments above
            const { data } = await studentApi.addStudent(this.selectedStudent);
            if (data.success !== false) {
              this.$snackbar.show(data.message || "Student added succesfully");
            } else {
              console.error(data);
              this.$snackbar.show(data.message || "Error during insert");
            }
            this.getStudents();
            this.dialog = false;
        }catch (err) {
          const errorMessage = err?.response?.data?.message || err?.message || "An unexpected error occurred";
          this.$snackbar.show(errorMessage);
          console.error(err);
        }
        this.clicked = false;
      },
  
      addGuardian() {
        if (!this.selectedStudent?.guardians) {
          this.$set(this.selectedStudent, "guardians", []); // to force the update
        }
        this.selectedStudent.guardians.push({
          id: null,
          fname: "",
          lname: "",
          email: "",
          password: "",
        });
      },
      
      removeGuardian(index) {
        this.selectedStudent.guardians.splice(index, 1);
      },
  
      onDelete(item) {
        this.selectedStudent = item;
        this.deleteDialog = true;
      },
      async confirmDelete() {
        this.clicked = true;
        try {
          const { data } = await studentApi.archive(this.selectedStudent.id);
          if (data.success !== false) {
            this.getStudents();
            this.$snackbar.show(data.message || "Student deleted succesfully");
            this.deleteDialog = false;
          } else {
            this.$snackbar.show(data.message || "Error on deleting");
          }
        } catch (err) {
          this.$snackbar.show(err.message);
        }
        this.clicked = false;
      },
  

      
      onPlaceSelected(place) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        this.selectedStudent.stop_name = place.formatted_address;
        this.selectedStudent.stop_lat = latitude;
        this.selectedStudent.stop_lon = longitude;
      },

      closeDialog(){
        this.dialog = false;
      },
      validateAndSave() {
        const isFormValid = this.$refs.form.validate();
      
        const areGuardiansValid = this.validateGuardians();
      
        if (!isFormValid || !areGuardiansValid) {
          this.$snackbar.show("Please fix the errors before saving.");
          return;
        }
      
        this.saveStudent();
      },
      validateGuardians() {
        if (!this.selectedStudent.guardians.length) return true; 
      
        for (const guardian of this.selectedStudent.guardians) {
          if (
            !guardian.fname ||
            !guardian.lname ||
            !guardian.email ||
            !/.+@.+\..+/.test(guardian.email) 
          ) {
            this.$snackbar.show("Please ensure all guardian fields are filled correctly.");
            return false;
          }
        }
        return true; 
      },

      selectExistingGuardian(index) {
        this.$set(this.selectGuardian, index, !this.selectGuardian[index]);
      
        if (this.selectGuardian[index]) {
          this.currentGuardianIndex = index; 
        } else {
          this.currentGuardianIndex = null;
        }
      },

      handleGuardianChange(selectedGuardian) {
        if (Array.isArray(this.selectedStudent.guardians) && this.currentGuardianIndex !== null) {
          this.$set(this.selectedStudent.guardians, this.currentGuardianIndex, selectedGuardian);
      
          this.currentGuardianIndex = null;
          this.selectedGuardian = null;
        } else {
          console.error("Guardians array is invalid or index is not set correctly.");
        }
      }
      


    },
  };