import api from "./api.js";
import AddWarehouseDialog from '@/components/dialogs/add_warehouse_dialog.vue';

var warehouse_term = localStorage.getItem("warehouse_term") || "Origin"
export default {
  components: {
    AddWarehouseDialog
  },
  data: () => ({
    tenantType: (sessionStorage.getItem("tenant_type") ?? null),
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    warehouse_term: warehouse_term,
    title: null,
    search: "",
    id: null,
    clicked: false,
    place: null,
    name: "",
    lat: null,
    lon: null,
    index: -1,
    myCurrentData: [],
    json_fields: {
      Id: "id",
      Name: "title",
    },
    data: [],
    headers: [
      { text: "Name", align: "center", value: "title"},
      { text: "Actions", value: "actions", align: "center"},
    ],
  }),
  mounted() {
    window.addEventListener("keyup", this.handleKeyPress);
    api
      .getwarehouses()
      .then((res) => {
        this.loading = false;
        if (res.data.responseCode === 1) {
          this.data = res.data.data;
        }
      })
      .catch((error) => {
        this.error = error;
        this.loading = false;
        this.errorDialog = true;
      });
  },
  beforeDestroy(){
    window.removeEventListener("keyup", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter") {
        if(this.dialog) this.save();
        if(this.deleteDialog) this.agreeOnDelete();
      }
      
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    showDialog(item) {
      this.selectedObject = item ? { ...item } : { id: -1 };
      this.index = item ? this.data.indexOf(item) : -1;
      this.dialog = true;
    },
    agreeOnDelete() {
      this.clicked = true;
      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.data.indexOf(this.selectedObject);
            this.$delete(this.data, index);
            this.$snackbar.show(`${warehouse_term} deleted successfully.`, "success");
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$snackbar.show(error, "error");
          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    onWarehouseAdded(newlyAddedWarehouse){
      this.data.push({
        id: newlyAddedWarehouse.id,
        title: newlyAddedWarehouse.title,
      });
    },
    onWarehouseUpdated(newlyAddedWarehouse, index){
      this.data[index].title = newlyAddedWarehouse.title;
    },
  },
};
